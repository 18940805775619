import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field } from 'redux-form';
import { renderCheckInput } from 'utils/renderCheckInput';
import { get, reduce } from 'lodash';
import { websiteSelector } from 'selectors';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';

const formName = 'ShareFeatures';
const features = [
  'shareViaMicrosoftTeamsFeatureEnabled',
  'shareViaYammerFeatureEnabled',
  'shareViaEmailFeatureEnabled',
  'shareViaFacebookFeatureEnabled',
  'shareViaTwitterFeatureEnabled',
  'shareDirectLinkFeatureEnabled',
];

function ShareFeatures(props) {
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="features.share.title">
      {features.map((featureName) => (
        <Field
          key={featureName}
          name={`${featureName}`}
          type="checkbox"
          title={t(`features.share.${featureName}`)}
          component={renderCheckInput}
        />
      ))}
    </Form>
  );
}

const updateWebsite = (id) => (dispatch) => (features) => {
  return api.patch(`/websites/${id}`, features).then((payload) => {
    dispatch(initialize(formName, getInitialFormValues(payload)));
    dispatch(changeWebsite(payload));
  });
};

const getInitialFormValues = (website) => {
  const getFeatures = (acc, value) => {
    acc[value] = get(website, value, false);
    return acc;
  };

  return reduce(features, getFeatures, {});
};

export const ShareFeaturesDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => ({
      formName,
      initialValues: getInitialFormValues(stateProps.website),
      onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(ShareFeatures);
