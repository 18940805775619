import { getI18n } from 'react-i18next';
import { format } from 'date-fns';
import { startsWith } from 'lodash';

export const formatDateTime = (date) => {
  if (!date) return '';

  const i18n = getI18n();
  const isDe = startsWith(i18n.language, 'de');
  return isDe ? format(new Date(date), 'dd.MM.yyyy HH:mm') : format(new Date(date), 'dd/MM/yyyy hh:mm a');
};
