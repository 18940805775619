import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Form } from 'components/Form';
import { api } from 'utils/api';
import { renderCheckInput } from 'utils/renderCheckInput';
import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';

import './index.css';

const formName = 'EditVideoMetadata';

const fields = ['showPlays', 'showUploader', 'showUploadDate'];

export function VideoMetadata(props) {
  const { t } = useTranslation();
  const { initialValues } = props;

  if (!initialValues) {
    return null;
  }

  return (
    <Form {...props} formTitle="content.videoMetadata.header">
      <div className="form-group edit-video-metadata-group">
        <label className="title form-label">{t('content.videoMetadata.label')}</label>
        {fields.map((fieldName) => {
          return (
            <Field
              key={fieldName}
              name={fieldName}
              type="checkbox"
              title={t(`content.videoMetadata.${fieldName}.label`)}
              component={renderCheckInput}
            />
          );
        })}
      </div>
      <small className="text-muted">{t('content.videoMetadata.hint')}</small>
    </Form>
  );
}

const saveVideoMetadata = (id) => (dispatch) => (videoBoxSettings) =>
  api.patch(`/websites/${id}`, { videoBoxSettings }).then((websitePayload) => {
    dispatch(initialize(formName, get(websitePayload, 'videoBoxSettings')));
    dispatch(changeWebsite(websitePayload));
  });

export const VideoMetadataDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      saveVideoMetadata,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      initialValues: get(stateProps.website, 'videoBoxSettings'),
      onSubmit: dispatchProps.saveVideoMetadata(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(VideoMetadata);
