import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { uiReducer } from './ui';
import { websitesReducer } from './websites';
import { appConfigReducer } from './appConfig';

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    ui: uiReducer,
    websites: websitesReducer,
    appConfig: appConfigReducer,
    vmpro: (state = {}) => state,
    isConsumerApp: (state = false) => state,
  });
