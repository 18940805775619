import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { FONTS_LOADED } from 'actions';
import { websiteIdSelector, cacheSelector } from 'selectors';
import { api } from 'utils/api';

import { FontSettingsDecorated } from './FontSettings';
import { CustomFontUpload } from './CustomFontUpload';

const setFonts = (payload) => ({
  type: FONTS_LOADED,
  payload,
});

const loadFonts = (websiteId) => (dispatch) => {
  return api.get(`/websites/${websiteId}/fonts`).then((payload) => dispatch(setFonts(payload)));
};

export function Typography() {
  const dispatch = useDispatch();
  const websiteId = useSelector(websiteIdSelector);
  const fonts = get(useSelector(cacheSelector), 'fonts');

  useEffect(() => {
    if (!fonts) {
      dispatch(loadFonts(websiteId));
    }
  }, [dispatch, fonts, websiteId]);

  return (
    <>
      <CustomFontUpload />
      <FontSettingsDecorated />
    </>
  );
}
