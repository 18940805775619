import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup } from 'react-bootstrap';
import { WarningModal } from 'components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { api } from 'utils/api';
import { FONT_DELETE } from 'actions';
import { getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { values, get, pick } from 'lodash';
import { websiteIdSelector, websiteSelector } from 'selectors';
import { Button } from 'components/Button';
import { extractFontName } from '../FontSettings';

const deleteFont = (fontId, setDeleting) => (dispatch, getState) => {
  const websiteId = websiteIdSelector(getState());

  setDeleting(true);

  return api
    .delete(`/websites/${websiteId}/fonts/${fontId}`)
    .then(() => {
      setDeleting(false);

      dispatch({
        type: FONT_DELETE,
        payload: {
          id: fontId,
        },
      });
    })
    .catch(() => {
      setDeleting(false);
    });
};

const fontsInUseSelector = (fontName) => (state) => {
  const fontSettingsFormValues = getFormValues('FontSettings')(state);

  const fontSettingsInitialValues = get(websiteSelector(state), 'customStyles.sassVariables');

  const fontsInUse = [
    ...values(pick(fontSettingsInitialValues, ['$fontTitle', '$fontText'])),
    ...values(pick(fontSettingsFormValues, ['$fontTitle', '$fontText'])),
  ];

  return fontsInUse.map(extractFontName).includes(fontName);
};

export function CustomFont({ font: { id, name } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isConfirmationShown, showConfirmation] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const isDeleteDisabled = useSelector(fontsInUseSelector(name));

  const toggleRemovalConfirmation = () => showConfirmation(!isConfirmationShown);

  const confirmRemoval = () => {
    toggleRemovalConfirmation();
    dispatch(deleteFont(id, setDeleting));
  };

  return (
    <>
      {isConfirmationShown && (
        <FontRemovalConfirmationDialog
          handleCancel={toggleRemovalConfirmation}
          handleOk={confirmRemoval}
          fontName={name}
        />
      )}
      <InputGroup>
        <Form.Control data-e2e="customFontTitle" value={name} disabled />
        <InputGroup.Append>
          <Button
            data-e2e="deleteCustomFont"
            variant="secondary"
            title={
              isDeleteDisabled
                ? t('design.customFontUpload.deleteDisabledMessage')
                : t('design.customFontUpload.deleteButtonMessage')
            }
            disabled={isDeleteDisabled}
            onClick={toggleRemovalConfirmation}
            isLoading={isDeleting}
          >
            {!isDeleting && <FontAwesomeIcon icon={faTimes} />}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </>
  );
}

function FontRemovalConfirmationDialog({ fontName, handleOk, handleCancel }) {
  const { t } = useTranslation();

  return (
    <WarningModal
      primaryButtonLabel="fileUploader.deleteConfirmationModal.primaryButtonLabel"
      header={t('fileUploader.deleteConfirmationModal.header')}
      handleOk={handleOk}
      isVisible
      handleCancel={handleCancel}
    >
      {t('fileUploader.deleteConfirmationModal.body', { fileName: fontName })}
    </WarningModal>
  );
}
