import React from 'react';
import { useSelector } from 'react-redux';

import { isNotConsumerAdminSelector } from 'selectors';
import { SSODecorated } from 'pages/Security/SSO';
import { ReferrerProtectionDecorated } from './ReferrerProtection';
import { ConsentConfigurationDecorated } from './ConsentConfiguration';

export function Security() {
  const isNotConsumerAdmin = useSelector(isNotConsumerAdminSelector);

  return (
    <>
      {isNotConsumerAdmin && <SSODecorated />}
      <ReferrerProtectionDecorated />
      <ConsentConfigurationDecorated />
    </>
  );
}
