import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import CloseButton from 'react-bootstrap/CloseButton';
import { bool, node, func, string } from 'prop-types';

import { Button } from 'components/Button';
import './index.css';

function ModalPortal({ isVisible, onClose, children }) {
  const [shouldRender, setShouldRender] = useState(isVisible);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
  }, [isVisible]);

  if (!document.getElementById('modal-root') || !shouldRender) return null;

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) onClose();
  };

  const handleAnimationEnd = (event) => {
    if (event.target === event.currentTarget && !isVisible) setShouldRender(false);
  };

  return createPortal(
    <div
      data-e2e="modal"
      className={`modal-background modal-background--${isVisible ? 'enter' : 'exit'}`}
      onClick={handleBackgroundClick}
      onAnimationEnd={handleAnimationEnd}
    >
      {children}
    </div>,
    document.getElementById('modal-root')
  );
}

ModalPortal.propTypes = {
  isVisible: bool,
  onClose: func,
  children: node,
};

function Modal({ isVisible, header, children, primaryButtonLabel, variant, onHide, handleCancel, handleOk }) {
  const { t } = useTranslation();

  return (
    <ModalPortal isVisible={isVisible} onClose={onHide || handleCancel}>
      <div className="modal__content">
        <div className="modal__header">
          {header}
          <CloseButton onClick={onHide || handleCancel} />
        </div>

        <div className="modal__body">{children}</div>

        {handleCancel && handleOk && (
          <div className="modal__buttons">
            <Button variant="secondary" type="button" data-e2e="secondaryButton" onClick={handleCancel}>
              {t('cancel')}
            </Button>

            <Button variant={variant || 'primary'} type="button" data-e2e="primaryButton" onClick={handleOk}>
              {t(primaryButtonLabel || 'ok')}
            </Button>
          </div>
        )}
      </div>
    </ModalPortal>
  );
}

Modal.propTypes = {
  isVisible: bool,
  header: node,
  children: node,
  primaryButtonLabel: string,
  variant: string,
  onHide: func,
  handleCancel: func,
  handleOk: func,
};

function WarningModal(props) {
  return <Modal variant="danger" {...props} />;
}
function ConfirmationModal(props) {
  return <Modal variant="primary" {...props} />;
}

export { Modal, WarningModal, ConfirmationModal };
