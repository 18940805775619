import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { createSelector } from 'reselect';

import { ColorPicker } from 'components/ColorPicker';
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { api } from 'utils/api';
import { Select } from 'components/Select';
import { changeWebsite } from 'actions';
import { websiteSelector, cacheSelector, appConfigSelector } from 'selectors';
import { isPositiveNumber, isEm, isColor } from 'utils/formValidators';
import { initialSassValuesSelector, nextCustomStylesSelector } from '../../selectors';

const formName = 'FontSettings';

const fontsSelector = createSelector(cacheSelector, (cache) => get(cache, 'fonts'));

const defaultSassVariablesSelector = createSelector(appConfigSelector, (config) =>
  get(config, `defValues.website.sassVariables`)
);

export function FontSettings({ regularTextfonts, headlineTextfonts, ...props }) {
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="design.typography.title">
      <div className="design-form__row-inline full-width">
        <Field
          name="$fontText"
          title={t('design.typography.regularTextFont.title')}
          component={Select}
          isSearchable
          options={regularTextfonts}
          formName={formName}
        />
        <Field
          name="$fontTitle"
          title={t('design.typography.headlineFont.title')}
          component={Select}
          isSearchable
          options={headlineTextfonts}
          formName={formName}
        />
      </div>

      <Field
        name="$defaultFontSize"
        description={t('design.typography.defaultFontSize.description')}
        title={t('design.typography.defaultFontSize.title')}
        component={Input}
        inputMaxWidthPx={200}
        validate={[isPositiveNumber, isEm]}
      />

      <div className="design-form__row-inline">
        <Field
          name="$basicTextColor"
          title={t('design.typography.basicTextColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
        <Field
          name="$basicTitleColor"
          title={t('design.typography.basicTitleColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
        <Field
          name="$basicPrimaryColor"
          title={t('design.typography.basicPrimaryColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
      </div>

      <div className="design-form__row-inline">
        <Field
          name="$mutedTextColor"
          title={t('design.typography.mutedTextColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
        <Field
          name="$basicErrorColor"
          title={t('design.typography.basicErrorColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
        <Field
          name="$highlightColor"
          title={t('design.typography.highlightColor.title')}
          description={t('design.typography.highlightColor.description')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
      </div>
    </Form>
  );
}

const getInitialValuesSelector = (state) =>
  initialSassValuesSelector(state, {
    pickBy: [
      '$fontText',
      '$fontTitle',
      '$defaultFontSize',
      '$basicTextColor',
      '$basicTitleColor',
      '$basicPrimaryColor',
      '$mutedTextColor',
      '$basicErrorColor',
      '$highlightColor',
    ],
  });

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const customStyles = nextCustomStylesSelector(getState(), {
    formValues,
  });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, getInitialValuesSelector(getState())));
    });
};

const createFontCSSRule = (fontName) => `'${fontName}',sans-serif`;

const createDefaultFontOption = (defaultCSSFontRule) => ({
  id: defaultCSSFontRule,
  name: `${extractFontName(defaultCSSFontRule)} (default)`,
});

const getInitialFonts = (fonts = [], defaultCSSFontRule) => {
  const initialFonts = [];
  if (defaultCSSFontRule) {
    initialFonts.push(createDefaultFontOption(defaultCSSFontRule));
  }

  return [
    ...initialFonts,
    ...fonts.map((font) => ({
      id: createFontCSSRule(font.name),
      name: font.name,
    })),
  ];
};

export const extractFontName = (fontCssRule) => fontCssRule && fontCssRule.split(',')[0].replace(/'/g, '');

export const FontSettingsDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      defaultVariables: defaultSassVariablesSelector(state),
      fonts: fontsSelector(state),
      initialValues: getInitialValuesSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      const fonts = get(stateProps, 'fonts', []);
      const $fontText = get(stateProps, 'defaultVariables.$fontText');
      const $fontTitle = get(stateProps, 'defaultVariables.$fontTitle');

      return {
        formName,
        regularTextfonts: getInitialFonts(fonts, $fontText),
        headlineTextfonts: getInitialFonts(fonts, $fontTitle),
        initialValues: stateProps.initialValues,
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(FontSettings);
