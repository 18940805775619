import React from 'react';
import ReactSelect from 'react-select';
import { arrayRemove, change, getFormValues, touch } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, get } from 'lodash';
import * as PropTypes from 'prop-types';

import { FormGroup } from '../FormGroup';
import { CustomOption } from './CustomOption';
import { ValueContainer } from './ValueContainer';
import { theme } from './style';
import './index.css';

const onChangeHandler = (dispatch, isMulti, formName, name, formValues, params, data) => {
  const { action, option, removedValue } = data;

  if (isMulti) {
    const currentFieldValues = get(formValues, name, []);

    if (action === 'select-option') {
      return dispatch(change(formName, name, [...currentFieldValues, option.id]));
    }

    if (action === 'clear') {
      return dispatch(change(formName, name, []));
    }

    const removeItem = (itemId) => {
      const idx = findIndex(currentFieldValues, (id) => id === itemId);

      if (idx !== -1) dispatch(arrayRemove(formName, name, idx));
    };

    if (action === 'deselect-option') {
      return removeItem(option.id);
    }

    if (removedValue) {
      return removeItem(removedValue.id);
    }

    if (action === 'pop-value') {
      // Backspace when there is no option selected should do nothing
      return null;
    }
  }

  return dispatch(change(formName, name, get(params, 'id', null)));
};

const getSelectableValue = (selectedValues, options) => {
  if (!Array.isArray(selectedValues)) {
    return options.find((o) => o.id === selectedValues);
  }

  return selectedValues.map((id) => options.find((o) => o.id === id));
};

export function Select({
  title,
  input: { name, value },
  description,
  placeholder,
  isMandatory,
  disabled,
  children,
  formName,
  meta,
  ...reactSelectProps
}) {
  const dispatch = useDispatch();

  const formValues = useSelector(getFormValues(formName));
  const {
    options,
    isMulti,
    isSearchable,
    isClearable,
    isDisabled,
    isOptionDisabled,
    className,
    hideSelectedOptions,
    closeMenuOnSelect,
    onInputFocus,
  } = reactSelectProps;

  const handleFocus = () => onInputFocus && onInputFocus();
  const handleBlur = () => dispatch(touch(formName, name));

  return (
    <FormGroup title={title} description={description} isMandatory={isMandatory} meta={meta} name={name}>
      <div data-e2e={name}>
        <ReactSelect
          components={{
            Option: CustomOption,
            ValueContainer,
          }}
          styles={theme}
          options={options}
          value={getSelectableValue(value, options)}
          isMulti={isMulti}
          placeholder={placeholder}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          className={`ct-select ${className}`}
          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option.name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onChangeHandler.bind(this, dispatch, isMulti, formName, name, formValues)}
        />
      </div>

      {children}
    </FormGroup>
  );
}

Select.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  formName: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  isMulti: false,
  isSearchable: false,
  isClearable: true,
  isOptionDisabled: () => false,
  hideSelectedOptions: false,
  closeMenuOnSelect: true,
  className: '',
  placeholder: '',
  options: [],
};
