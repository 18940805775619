/* eslint-disable */
export class LocalStoragePlugin {
  static type = 'languageDetector';

  constructor(services, options = {}) {
    this.init(services, options);
  }

  init(services, options = {}, i18nOptions = {}) {
    const defaults = {
      forcedLng: null,
      lookupName: 'i18nLng',
    };

    this.services = services;
    this.i18nOptions = i18nOptions;
    this.localStorageOptions = {
      ...defaults,
      ...i18nOptions.localStorage,
    };
  }

  detect() {
    let lng = window.localStorage.getItem(this.localStorageOptions.lookupName);

    if (!lng) {
      lng = this.getFallbackLng();
    }

    return this.services.languageUtils.formatLanguageCode(lng);
  }

  cacheUserLanguage(lng) {
    window.localStorage.setItem(this.localStorageOptions.lookupName, lng);
  }

  getFallbackLng() {
    return this.i18nOptions.fallbackLng[0];
  }
}
