import {
  HIDE_NOTIFICATION,
  SET_FILTER_STATUSES,
  SHOW_NOTIFICATION,
  TOGGLE_CREATE_CT_MODAL,
  TOGGLE_DELETE_CT_MODAL,
  SET_FILTER_KEYWORD,
  SET_FILTER_ACCESS_LEVELS,
  SET_CORPORATE_TUBES_SORTING,
  TOGGLE_ACCOUNT_CONFIRMATION_DIALOG,
  ADD_TOGGLES,
  PLAYERS_LOADED,
  CHANNELS_LOADED,
  FONTS_LOADED,
  CLEAR_CACHE,
  FONT_DELETE,
  FONT_ADD,
} from 'actions';
import { filter, get } from 'lodash';
import { uid } from 'utils/uid';

const initialState = {
  isCreateCTModalShown: false,
  isDeleteCTModalShown: false,
  isAccountConfirmationDialogShown: false,
  notifications: [],
  selectedStatuses: [],
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CREATE_CT_MODAL:
      return {
        ...state,
        isCreateCTModalShown: !state.isCreateCTModalShown,
      };
    case TOGGLE_DELETE_CT_MODAL:
      return {
        ...state,
        isDeleteCTModalShown: !state.isDeleteCTModalShown,
      };
    case SHOW_NOTIFICATION: {
      const notification = {
        id: uid(),
        ...action.payload,
      };

      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    }
    case HIDE_NOTIFICATION: {
      const { id } = action.payload;
      return {
        ...state,
        notifications: filter(state.notifications, (n) => n.id !== id),
      };
    }

    case SET_FILTER_ACCESS_LEVELS:
      return {
        ...state,
        setSelectedAccessLevels: action.payload,
      };

    case SET_FILTER_STATUSES:
      return {
        ...state,
        selectedStatuses: action.payload,
      };

    case SET_FILTER_KEYWORD:
      return {
        ...state,
        filterCorporateTubesBy: action.payload,
      };

    case SET_CORPORATE_TUBES_SORTING:
      return {
        ...state,
        sortCorporateTubesBy: action.payload,
      };

    case TOGGLE_ACCOUNT_CONFIRMATION_DIALOG:
      return {
        ...state,
        isAccountConfirmationDialogShown: !state.isAccountConfirmationDialogShown,
      };

    case ADD_TOGGLES:
      return {
        ...state,
        toggles: action.payload,
      };

    case PLAYERS_LOADED:
      return {
        ...state,
        cache: {
          ...state.cache,
          players: action.payload,
        },
      };

    case CHANNELS_LOADED:
      return {
        ...state,
        cache: {
          ...state.cache,
          channels: action.payload,
        },
      };

    case FONTS_LOADED:
      return {
        ...state,
        cache: {
          ...state.cache,
          fonts: action.payload,
        },
      };

    case FONT_DELETE: {
      const fontId = get(action.payload, 'id');
      return {
        ...state,
        cache: {
          ...state.cache,
          fonts: filter(get(state.cache, 'fonts'), (font) => font.id !== fontId),
        },
      };
    }

    case FONT_ADD:
      return {
        ...state,
        cache: {
          ...state.cache,
          fonts: [...state.cache.fonts, action.payload],
        },
      };

    case CLEAR_CACHE:
      return {
        ...state,
        cache: null,
      };

    default:
      return state;
  }
};
