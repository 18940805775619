import React from 'react';
import { string, shape, bool, arrayOf } from 'prop-types';
import { Form } from 'react-bootstrap';
import { map } from 'lodash';
import { FormGroup } from 'components/FormGroup';
import { uid } from 'utils/uid';

import './index.css';

export function CheckboxGroup({
  input: { name, onChange, onBlur, value },
  meta,
  title,
  description,
  isMandatory,
  disabled,
  options,
  translateOption,
}) {
  const uniqueId = uid();

  const handleChange = (e) => {
    const newValue = [...value];
    if (e.target.checked) {
      newValue.push(e.target.name);
    } else {
      newValue.splice(newValue.indexOf(e.target.name), 1);
    }

    return onChange && onChange(newValue);
  };

  return (
    <FormGroup title={title} description={description} isMandatory={isMandatory} meta={meta} name={name}>
      <div className="checkbox-group">
        {map(options, (option) => (
          <div className="custom-control custom-checkbox" key={option}>
            <input
              type="checkbox"
              className="custom-control-input"
              data-e2e={`${name}-${option}`}
              disabled={disabled}
              name={option}
              checked={value.includes(option)}
              id={`${option}-${uniqueId}`}
              onChange={handleChange}
              onBlur={() => onBlur(value)}
            />
            <Form.Label htmlFor={`${option}-${uniqueId}`} className="title custom-control-label">
              {translateOption(option)}
            </Form.Label>
          </div>
        ))}
      </div>
    </FormGroup>
  );
}

CheckboxGroup.defaultProps = {
  meta: { touched: false, error: false },
  value: [],
  options: [],
};

CheckboxGroup.propTypes = {
  meta: shape({
    touched: bool,
    error: bool,
  }),
  name: string,
  title: string,
  description: string,
  value: arrayOf(string),
  options: arrayOf(string),
};
