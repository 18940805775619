import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';

import { Form } from 'components/Form';
import { Select } from 'components/Select';
import { websiteSelector, isConsumerAdminSelector } from 'selectors';
import { changeWebsite } from 'actions';
import { api } from 'utils/api';
import { channelsSelector } from 'pages/Content/selectors';

import { loadChannelOptions, replaceRootChannelName } from '../channelsHelper';
import { renderSortedVideoList } from './SortedVideoList';
import './index.css';

const formName = 'HomePageChannels';

export function HomePageChannels(props) {
  const { channels, loadChannelOptions, hasSsoEnabled, isConsumerAdmin, initialValues } = props;
  const { t } = useTranslation();

  const isStartVideoChannelFieldVisible = !isConsumerAdmin || !hasSsoEnabled;
  const isStartVideoChannelEnabled = isConsumerAdmin || !hasSsoEnabled;
  const startVideoChannelPlaceholder = isStartVideoChannelEnabled
    ? t('content.home.startVideoChannelId.placeholder')
    : t('content.home.startVideoChannelId.placeholderFieldDisabled');
  const channelOptions = replaceRootChannelName(channels, t);

  return (
    <Form {...props} formTitle="content.channels.header">
      {isStartVideoChannelFieldVisible && (
        <div className="form-group">
          <Field
            name="homePage.startVideoChannelId"
            title={t('content.home.startVideoChannelId.label')}
            placeholder={startVideoChannelPlaceholder}
            description={t('content.home.startVideoChannelId.hint')}
            component={Select}
            isSearchable
            options={channelOptions}
            isDisabled={!isStartVideoChannelEnabled}
            onInputFocus={loadChannelOptions}
            formName={formName}
          />
        </div>
      )}

      {initialValues.homeVideoListSettings && (
        <div className="form-group">
          <Field
            name="homeVideoListSettings"
            title={t('content.home.homeVideoListSettings.label')}
            component={renderSortedVideoList}
            description={t('content.home.homeVideoListSettings.hint')}
          />
        </div>
      )}

      <div className="form-group">
        <Field
          name="homePage.channelIds"
          title={t('content.home.homeChannelIds.label')}
          placeholder={t('content.home.homeChannelIds.placeholder')}
          description={t('content.home.homeChannelIds.hint')}
          component={Select}
          isMulti
          isSearchable
          options={channelOptions}
          onInputFocus={loadChannelOptions}
          formName={formName}
        >
          <div className="form-check">
            <Field
              className="form-check-input"
              name="homePage.useAsRoot"
              id="homeChannelIdsUseAsRoot"
              data-e2e="homePage.useAsRoot"
              type="checkbox"
              component="input"
            />
            <label htmlFor="homeChannelIdsUseAsRoot" className="form-check-label channels_form-check-label">
              {t('content.home.homeChannelIdsUseAsRoot.label')}
            </label>
          </div>
        </Field>
      </div>
    </Form>
  );
}

export const getInitialFormValues = (website) => ({
  navigation: {
    channelIds: [],
    useAsRoot: false,
  },
  homePage: {
    channelIds: [],
    useAsRoot: false,
    startVideoChannelId: null,
  },
  homeVideoListSettings: get(website, 'homeVideoListSettings'),
  ...get(website, 'content'),
});

const saveChannels = (id) => (dispatch, getState) => (formData) => {
  const { homePage, homeVideoListSettings } = formData;
  const content = get(websiteSelector(getState()), 'content');

  return api
    .patch(`/websites/${id}`, {
      content: {
        ...content,
        homePage,
      },
      homeVideoListSettings,
    })
    .then((websitePayload) => {
      dispatch(initialize(formName, getInitialFormValues(websitePayload)));
      dispatch(changeWebsite(websitePayload));
    })
    .catch(noop);
};

export const HomePageChannelsDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      channels: channelsSelector(state),
      isConsumerAdmin: isConsumerAdminSelector(state),
      formName,
    }),
    {
      saveChannels,
      loadChannelOptions,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: getInitialFormValues(stateProps.website),
        channels: stateProps.channels,
        isConsumerAdmin: stateProps.isConsumerAdmin,
        hasSsoEnabled: get(stateProps.website, 'sso.enabled', false),
        loadChannelOptions: dispatchProps.loadChannelOptions(websiteId, stateProps.channels),
        onSubmit: dispatchProps.saveChannels(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(HomePageChannels);
