import React from 'react';
import { string, shape, bool } from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './index.css';

export function FormGroup({ title, description, isMandatory, children, name, meta: { touched, error } }) {
  const { t } = useTranslation();

  return (
    <Form.Group>
      {title && (
        <Form.Label className="title">
          {title}
          {isMandatory ? <span>&nbsp;*</span> : null}
        </Form.Label>
      )}
      {children}
      {touched && error && (
        <Form.Control.Feedback data-e2e={`${name}-error`} type="invalid">
          {t(error)}
        </Form.Control.Feedback>
      )}
      {description && <Form.Text className="text-muted">{description}</Form.Text>}
    </Form.Group>
  );
}

FormGroup.defaultProps = {
  meta: { touched: false, error: false },
  isMandatory: false,
};

FormGroup.propTypes = {
  title: string,
  description: string,
  name: string,
  meta: shape({
    touched: bool,
    error: string,
  }),
};
