import Keycloak from 'keycloak-js';
import { config } from 'config';
import { CtTokenRepository } from 'utils/ct-token.repository';
import { callApi } from 'utils/http-client';

if (!window.__CT_KEYCLOAK) {
  window.__CT_KEYCLOAK = Keycloak;
}

const minValidity = 30;
let keycloakClient;

const fetchCtTokenApi = (SSOToken) => callApi(`/auth/login`, `POST`, { SSOToken }, false);

const fetchCtToken = (kcToken) => {
  CtTokenRepository.delete();

  return fetchCtTokenApi(kcToken).then((ctToken) => {
    CtTokenRepository.persist(ctToken.token);
    return ctToken.token;
  });
};

export const updateToken = () =>
  keycloakClient
    ? keycloakClient.updateToken(minValidity).then(() => fetchCtToken(keycloakClient.token))
    : Promise.reject('Function "updateToken" not supported yet, because keycloakClient not available.'); // eslint-disable-line prefer-promise-reject-errors

export const initAuth = () => {
  const { url, realm, clientId, idpHint } = config.keycloak;
  const options = {
    onLoad: 'check-sso',
    promiseType: 'native',
  };

  keycloakClient = new window.__CT_KEYCLOAK({ url, realm, clientId });
  keycloakClient.onTokenExpired = () => updateToken();

  return keycloakClient
    .init(options)
    .then((authenticated) => (authenticated ? fetchCtToken(keycloakClient.token) : keycloakClient.login({ idpHint })));
};
