import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { websiteSelector } from 'selectors';
import { MenuItems } from 'components/Menu';
import { routes } from 'routes';

const menuItems = [
  {
    id: 'general',
  },
  {
    id: 'content',
  },
  {
    id: 'security',
  },
  {
    id: 'features',
  },
  {
    id: 'design',
    children: [
      { id: 'global' },
      { id: 'typography' },
      { id: 'header' },
      { id: 'navigation' },
      { id: 'contentArea' },
      { id: 'footer' },
      { id: 'miscellaneous' },
    ],
  },
];

export function GlobalMenu() {
  const { t } = useTranslation();

  const website = useSelector(websiteSelector);
  const getRouteSegment = (id) => generatePath(routes[id].split('/').pop());

  return (
    <div data-e2e="globalAdminMenu">
      <div className="menu__all-ct">
        <Link to="/" data-e2e="toAllCorporateTubes">
          <span className="menu__arrow-icon">
            <FontAwesomeIcon icon={faArrowLeft} size="xs" />
          </span>
          {t('allCorporateTubes')}
        </Link>
      </div>
      {!isEmpty(website) && <div className="menu__title">{`${website.company} - ${website.name}`}</div>}
      <div className="menu-items">
        <MenuItems items={menuItems} getItemLink={getRouteSegment} />
      </div>
    </div>
  );
}
