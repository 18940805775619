export const theme = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
    borderRadius: 2,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    '&:hover': {
      borderColor: 'none',
    },
    minWidth: '200px',
  }),
  multiValue: (provided) => ({
    ...provided,
    padding: '1px 1px 1px 6px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
};
