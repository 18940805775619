import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty, get } from 'lodash';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { filterCorporateTubesBySelector } from 'selectors';
import { SET_FILTER_KEYWORD } from 'actions';

import './index.css';

const setFilterKeyword = (payload) => ({
  type: SET_FILTER_KEYWORD,
  payload,
});

export function KeywordFilter() {
  const { t } = useTranslation();
  const placeholder = `${capitalize(t('company'))} / ${capitalize(t('title'))}`;

  const value = useSelector(filterCorporateTubesBySelector) || '';
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setFilterKeyword(get(event, 'target.value')));
  };

  const clearFilter = () => {
    dispatch(setFilterKeyword(''));
  };

  return (
    <div className="search-input">
      <InputGroup size="sm">
        <Form.Control
          type="text"
          size="sm"
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          data-e2e="filter-input"
        />
        {!isEmpty(value) ? (
          <InputGroup.Append data-e2e="clear-filter" className="search__delete-action" onClick={clearFilter}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faTimesCircle} />
            </InputGroup.Text>
          </InputGroup.Append>
        ) : (
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </div>
  );
}
