import { createSelector } from 'reselect';
import { get, noop } from 'lodash';

import { api } from 'utils/api';
import { cacheSelector } from 'selectors';
import { CHANNELS_LOADED } from 'actions';

function getSubChannels(channels, parentChannel) {
  return channels.filter((channel) => channel.parentId === parentChannel.id);
}

function isParent(channels, parentChannel) {
  return channels.filter((channel) => channel.parentId === parentChannel.id).length > 0;
}

const getParent = (currentChannel, channels) => {
  const results = channels.filter((channel) => channel.id === currentChannel.parentId);

  return results.length ? results[0] : null;
};

const getLevelOfChannel = (channel, channels, level) => {
  const parent = getParent(channel, channels);
  if (parent) return getLevelOfChannel(parent, channels, level + 1);
  return level;
};

const getChannelOption = (channel, channels) => {
  const level = getLevelOfChannel(channel, channels, 1);
  return {
    name: channel.name,
    level,
    id: channel.id,
    parentId: channel.parentId,
  };
};

const sortOptions = (options, option, sortedOptions) => {
  sortedOptions.push(option);
  if (isParent(options, option)) {
    const children = getSubChannels(options, option);
    children.map((child) => sortOptions(options, child, sortedOptions));
  }
};

const getChannelsOptions = (channels) => {
  const options = channels.map((channel) => getChannelOption(channel, channels));
  const sortedOptions = [];

  options.filter((option) => !option.parentId).map((option) => sortOptions(options, option, sortedOptions));

  return sortedOptions;
};

const setChannels = (payload) => ({
  type: CHANNELS_LOADED,
  payload,
});

export const channelsSelector = createSelector(cacheSelector, (cache) => get(cache, 'channels'));

export const replaceRootChannelName = (channelOptions, t) => {
  if (!channelOptions || !channelOptions.length) {
    return channelOptions;
  }

  return channelOptions.map((channel) => {
    const isRoot = channel.name === 'root_channel' && !channel.parentId;

    return isRoot ? { ...channel, name: t('root_channel') } : channel;
  });
};

export const loadChannelOptions = (id, currentChannels) => (dispatch) => () =>
  currentChannels
    ? noop
    : api
        .get(`/websites/${id}/channels`)
        .then((channels) => dispatch(setChannels(getChannelsOptions(channels))))
        .catch(noop);
