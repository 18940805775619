import React from 'react';
import { Button } from 'components/Button';

import './Buttons.css';

export function Buttons({
  pristine,
  reset,
  submitting,
  valid,
  primaryTitle,
  secondaryTitle,
  submittingTitle,
  secondaryDisabled,
  primaryVariant,
  disabled,
}) {
  return (
    <div className="buttons__content">
      <div className="buttons__button">
        <Button
          variant="secondary"
          disabled={disabled || secondaryDisabled}
          onClick={reset}
          type="button"
          data-e2e="secondaryButton"
        >
          <div className="buttons__button-title">{secondaryTitle}</div>
        </Button>
      </div>
      <div className="buttons__button">
        <Button
          type="submit"
          variant={primaryVariant}
          disabled={disabled || pristine || submitting || !valid}
          data-e2e="primaryButton"
          isLoading={submitting}
        >
          <div className="button__title">{submitting ? submittingTitle : primaryTitle}</div>
        </Button>
      </div>
    </div>
  );
}

Buttons.defaultProps = {
  primaryVariant: 'primary',
};
