import React from 'react';
import { isString, map } from 'lodash';
import { getHash } from 'utils/hash';
import { Table as BootstrapTable } from 'react-bootstrap';
import { SortColumn } from './SortColumn';

import './index.css';

const generateKey = (o) =>
  isString(o)
    ? getHash(o)
    : Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);

function Header({ header }) {
  return (
    <thead>
      <tr>
        {map(header, ({ content, sortable }) => (
          <th key={generateKey(content)}>
            {content}
            {sortable && <SortColumn field={content} />}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function Row({ row, handleClick }) {
  return (
    <tr data-e2e={`row-${row.id}`} onClick={() => handleClick(row.id)}>
      {map(row.cols, ({ content, truncate }, idx) => (
        <td data-e2e={`col-${idx}`} key={row.id + idx} className={truncate ? 'text-truncate' : null}>
          {content}
        </td>
      ))}
    </tr>
  );
}

function EmptyRow({ cols, emptyMessage }) {
  return (
    <tr className="empty-row" data-e2e="empty-row">
      <td colSpan={cols}>{emptyMessage}</td>
    </tr>
  );
}

const getRows = (rows, emptyMessage, header, handleClick) => {
  return rows && rows.length ? (
    map(rows, (row) => <Row key={row.id} row={row} handleClick={handleClick} />)
  ) : (
    <EmptyRow emptyMessage={emptyMessage} cols={header.length} />
  );
};

export function Table({ header, rows, emptyMessage, handleClick }) {
  const showEmptyMessage = !rows.length && emptyMessage;
  const tableAttributes = !showEmptyMessage
    ? { bordered: true, responsive: true, striped: true, hover: true }
    : { bordered: true, responsive: true };

  return (
    <BootstrapTable {...tableAttributes}>
      {header && <Header header={header} />}
      <tbody>{getRows(rows, emptyMessage, header, handleClick)}</tbody>
    </BootstrapTable>
  );
}
