import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { isEmpty } from 'lodash';

import { routes } from 'routes';
import { GeneralSettings } from 'pages/GeneralSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

import { Content } from 'pages/Content';
import { websiteSelector, isConsumerAdminSelector, websiteIdSelector } from 'selectors';
import { StatusDropdownDecorated } from 'pages/CorporateTube/StatusDropdown';
import { DeleteButtonDecorated } from 'pages/CorporateTube/DeleteButton';
import { NotFoundPage } from 'pages/NotFoundPage';
import { Security } from 'pages/Security';
import { Features } from 'pages/Features';
import { clearCache } from 'actions';
import { GlobalDecorated } from 'pages/Design/Global';
import { HeaderDecorated } from 'pages/Design/Header';
import { Typography } from 'pages/Design/Typography';
import { NavigationDecorated } from 'pages/Design/Navigation';
import { ContentAreaDecorated } from 'pages/Design/ContentArea';
import { FooterDecorated } from 'pages/Design/Footer';
import { Miscellaneous } from 'pages/Design/Miscellaneous';

import './website.css';

export function Website() {
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);
  const website = useSelector(websiteSelector);
  const websiteId = useSelector(websiteIdSelector);
  const dispatch = useDispatch();
  const websiteUrl = website ? `https://${website.hostname}` : '';

  useEffect(() => {
    dispatch(clearCache());
  }, [websiteId, dispatch]);

  return isEmpty(website) ? null : (
    <>
      <div className="website__toolbar" data-e2e="ct-toolbar">
        <div className="website__header">
          <span data-e2e="websiteCompany">{website.company}</span>
          <span className="website__separator">-</span>
          <a
            data-e2e="websiteName"
            className="website__title"
            href={websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-tooltip-content={websiteUrl}
            data-tooltip-id="websiteUrlTooltip"
          >
            {website.name}
            <FontAwesomeIcon className="website-title__icon" icon={faExternalLink} />
          </a>
        </div>

        <Tooltip id="websiteUrlTooltip" place="top" effect="solid" type="dark" globalEventOff="click" />

        {!isConsumerAdmin && (
          <div className="website__actions">
            <div className="mr-2">
              <DeleteButtonDecorated />
            </div>
            <StatusDropdownDecorated />
          </div>
        )}
      </div>
      <Switch>
        <Route path={routes.content} component={Content} />
        <Route path={routes.global} component={GlobalDecorated} />
        <Route path={routes.typography} component={Typography} />
        <Route path={routes.header} component={HeaderDecorated} />
        <Route path={routes.navigation} component={NavigationDecorated} />
        <Route path={routes.contentArea} component={ContentAreaDecorated} />
        <Route path={routes.footer} component={FooterDecorated} />
        <Route path={routes.miscellaneous} component={Miscellaneous} />
        <Route path={routes.features} component={Features} />
        <Route path={routes.general} component={GeneralSettings} />
        <Route path={routes.security} component={Security} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}
