import React from 'react';
import { isEmpty, last, pipe, orderBy, reduce } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { filterCTByKeywordsSelector } from '../../selectors';

const getHighlightSections = (text, keywords) => {
  if (isEmpty(text) || isEmpty(keywords)) {
    return [];
  }

  return pipe(
    reduce((result, keyword) => {
      const from = text.toLowerCase().indexOf(keyword.toLowerCase());

      if (from >= 0) {
        result.push({ from, to: from + keyword.length });
      }

      return result;
    }, []),
    orderBy(['from'], ['asc']),
    reduce((acc, section) => {
      const { from, to } = section;
      const lastSection = last(acc);

      if (lastSection && from <= lastSection.to) {
        lastSection.to = to;
      } else {
        acc.push({ from, to });
      }

      return acc;
    }, [])
  )(keywords);
};

const getText = (text, sections) => {
  if (isEmpty(sections)) {
    return text;
  }

  return sections.reduce((elements, currentSection, index) => {
    const { from, to } = currentSection;

    if (from > 0) {
      const prevSection = sections[index - 1];
      const begin = prevSection ? prevSection.to : 0;
      elements.push(text.substring(begin, from));
    }

    const boldText = text.substring(from, to);
    elements.push(
      <b key={boldText} data-e2e={`highlight-${index}`}>
        {boldText}
      </b>
    );

    const isLastSection = index === sections.length - 1;
    const hasPostfix = to < text.length;

    if (isLastSection && hasPostfix) {
      elements.push(text.substring(to, text.length));
    }

    return elements;
  }, []);
};

export function HightlightedContent({ text }) {
  const keywords = useSelector(filterCTByKeywordsSelector);
  const sections = getHighlightSections(text, keywords);

  return <>{getText(text, sections)}</>;
}
