import React from 'react';
import { Spinner, Button as BootstrapButton } from 'react-bootstrap';
import { omit } from 'lodash';

import './index.css';

function Button(props) {
  const { isLoading, children } = props;

  return (
    <BootstrapButton {...omit(props, 'isLoading')}>
      <div className="button__label">
        {isLoading && (
          <div data-e2e="button-spinner">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="button__spinner"
            />
          </div>
        )}
        {children}
      </div>
    </BootstrapButton>
  );
}

export { Button };
