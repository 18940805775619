import React, { useState, useEffect } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { bool, func, shape } from 'prop-types';

import { WarningModal } from 'components/Modal';

function LeavingModal(props) {
  const { t } = useTranslation();

  return (
    <WarningModal {...props} header={t('routeLeavingGuardTitle')}>
      {t('routeLeavingGuardBody')}
    </WarningModal>
  );
}

function RouteLeavingGuard({ when, history }) {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [lastLocation, setLocation] = useState();
  const [isNavigationConfirmed, setNavigationConfirmation] = useState(false);

  const closeModal = () => setModalVisibility(false);

  const closeAndGo = () => {
    closeModal();
    setNavigationConfirmation(true);
  };

  const showModal = (nextLocation) => {
    if (!isNavigationConfirmed) {
      setModalVisibility(true);
      setLocation(nextLocation);
    }
    return isNavigationConfirmed;
  };

  useEffect(() => {
    if (!when && isNavigationConfirmed) {
      setNavigationConfirmation(false);
    }
  }, [when, isNavigationConfirmed]);

  useEffect(() => {
    if (isNavigationConfirmed && !isModalVisible) {
      history.push(lastLocation.pathname);
    }
  }, [isModalVisible, isNavigationConfirmed, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={showModal} />
      <LeavingModal handleOk={closeAndGo} isVisible={isModalVisible} handleCancel={closeModal} />
    </>
  );
}

RouteLeavingGuard.propTypes = {
  when: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export const RouteLeavingGuardDecorated = compose(withRouter)(RouteLeavingGuard);
