import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { websiteSelector } from 'selectors';

import { formatDateTime } from 'utils/formatDateTime';

export function ActivityPanel() {
  const { t } = useTranslation();
  const website = useSelector(websiteSelector);
  const createdOn = get(website, 'logs[0].createdAt');
  const createdBy = get(website, 'logs[0].createdBy');
  const changedOn = get(website, 'logs[1].createdAt');
  const changedBy = get(website, 'logs[1].createdBy');
  const showCreated = createdOn && createdBy;
  const showChanged = changedOn && changedBy;

  return showCreated || showChanged ? (
    <div className="cta-activity-panel">
      <label className="d-block">{t('generalInfoForm.activity')}</label>
      {showCreated && (
        <small className="d-block">
          <span className="text-muted">{t('generalInfoForm.created')}</span>
          <span className="px-1" data-e2e="createdOn">
            {formatDateTime(createdOn)}
          </span>
          <span className="text-muted">{t('generalInfoForm.by')}</span>
          <span className="pl-1" data-e2e="createdBy">
            {createdBy}
          </span>
        </small>
      )}
      {showChanged && (
        <small className="d-block">
          <span className="text-muted">{t('generalInfoForm.changed')}</span>
          <span className="px-1" data-e2e="changedOn">
            {formatDateTime(changedOn)}
          </span>
          <span className="text-muted">{t('generalInfoForm.by')}</span>
          <span className="pl-1" data-e2e="changedBy">
            {changedBy}
          </span>
        </small>
      )}
    </div>
  ) : null;
}
