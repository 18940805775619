import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field, getFormValues, getFormInitialValues } from 'redux-form';

import { Input } from 'components/Input';
import { isRequired, isNumber } from 'utils/formValidators';
import { get, has, toString } from 'lodash';
import { websiteSelector, appConfigSelector } from 'selectors';
import { Form } from 'components/Form';
import { confirmChanges, formName } from './actions';
import './index.css';

export function Account(props) {
  const { t } = useTranslation();
  const { website, isUsernameDirty } = props;
  const isNewAccount = !has(website, 'account');
  const isPasswordRequired = isNewAccount || (!isNewAccount && isUsernameDirty);
  const passwordValidator = isPasswordRequired ? isRequired : null;

  return (
    <Form {...props} formTitle="Account">
      <Field
        name="videoManagerId"
        title={t('videoManagerId')}
        description={t('videoManagerIdDescription')}
        component={Input}
        isMandatory
        validate={[isRequired, isNumber]}
      />
      <div className="account__credentials">
        <div className="account__credentials-item">
          <Field name="username" title={t('username')} component={Input} isMandatory validate={isRequired} />
        </div>
        <div className="account__credentials-item">
          <Field
            type="password"
            name="password"
            title={t('password')}
            component={Input}
            isMandatory={isPasswordRequired}
            validate={passwordValidator}
          />
        </div>
      </div>
      <Field
        name="apiBaseUrl"
        description={t('apiBaseUrlDescription')}
        title={t('apiBaseUrl')}
        component={Input}
        isMandatory
        validate={isRequired}
      />
      <Field
        name="authUrl"
        description={t('authUrlDescription')}
        title={t('authUrl')}
        component={Input}
        isMandatory
        validate={isRequired}
      />
      <Field name="iqsClientId" title={t('iqsClient.id')} component={Input} />
      <Field name="iqsClientSecret" title={t('iqsClient.secret')} component={Input} />
      <Field name="iqsApiBaseUrl" title={t('iqsClient.baseUrl')} component={Input} />
      <Field name="iqsEnv" title={t('iqsClient.env.title')} description={t('iqsClient.env.hint')} component={Input} />
    </Form>
  );
}

const appConfigAccountSelector = (state) => {
  const settings = appConfigSelector(state);
  return get(settings, 'defValues.website.account');
};

export const AccountDecorated = compose(
  connect(
    (state) => {
      const website = websiteSelector(state);
      const videoManagerId = get(website, 'account.videoManagerId');
      const defaultAccount = appConfigAccountSelector(state);

      const initialValues = {
        ...defaultAccount,
        ...get(website, 'account'),
        videoManagerId: toString(videoManagerId),
      };
      const formValues = getFormValues(formName)(state);
      const formInitialValues = getFormInitialValues(formName)(state);
      const isUsernameDirty = get(formValues, 'username') !== get(formInitialValues, 'username');

      return {
        formName,
        website,
        initialValues,
        isUsernameDirty,
      };
    },
    {
      onSubmit: confirmChanges,
    }
  ),
  reduxForm({
    form: formName,
    touchOnBlur: true,
    enableReinitialize: true,
  })
)(Account);
