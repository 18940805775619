import { initialize, getFormValues, stopSubmit, startSubmit } from 'redux-form';
import { get, trim, toString } from 'lodash';
import { toggleAccountConfirmationDialog, changeWebsite, clearCache } from 'actions';
import { websiteSelector } from 'selectors';
import { api } from 'utils/api';

const normalizeVideoManagerId = (value) => (Number.isNaN(Number(value)) ? value : Number(value));

export const formName = 'GeneralSettingsAccount';

export const confirmChanges = () => (dispatch, getState) => {
  const formAccount = getFormValues(formName)(getState());

  const nextVideoManagerId = normalizeVideoManagerId(formAccount.videoManagerId);

  const website = websiteSelector(getState());
  const videoManagerId = get(website, 'account.videoManagerId');

  if (videoManagerId && videoManagerId !== nextVideoManagerId) {
    return dispatch(toggleAccountConfirmationDialog());
  }

  dispatch(updateAccount());

  return null;
};

const removeEmptyPassword = (formAccount) => {
  const { password, ...account } = formAccount;
  return trim(formAccount.password) === '' ? account : formAccount;
};

export const updateAccount = () => (dispatch, getState) => {
  const formAccount = getFormValues(formName)(getState());

  dispatch(startSubmit(formName));

  const nextVideoManagerId = normalizeVideoManagerId(formAccount.videoManagerId);

  const account = {
    ...removeEmptyPassword(formAccount),
    videoManagerId: nextVideoManagerId,
  };
  const website = websiteSelector(getState());

  return api
    .patch(`/websites/${get(website, 'id')}`, { account })
    .then((payload) => {
      const { account } = payload;
      dispatch(
        initialize(formName, {
          ...account,
          videoManagerId: toString(account.videoManagerId),
        })
      );
      dispatch(changeWebsite(payload));

      const videoManagerId = get(website, 'account.videoManagerId');
      if (videoManagerId && videoManagerId !== nextVideoManagerId) {
        dispatch(clearCache());
      }
    })
    .catch(({ errors }) => dispatch(stopSubmit('GeneralSettingsAccount', errors)));
};
