import React from 'react';
import { string, func, shape } from 'prop-types';
import { Form } from 'react-bootstrap';
import { FormGroup } from 'components/FormGroup';

import './index.css';

export function Input({
  title,
  input: { name, onChange, onBlur, value },
  description,
  placeholder,
  isMandatory,
  disabled,
  children,
  meta,
  inputMaxWidthPx,
}) {
  const { touched, error } = meta;
  const handleChange = (e) => onChange && onChange(e.target.value);
  const handleBlur = (e) => onBlur && onBlur(e.target.value);

  return (
    <FormGroup title={title} description={description} isMandatory={isMandatory} meta={meta} name={name}>
      <Form.Control
        type="text"
        size="sm"
        name={name}
        data-e2e={name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        isInvalid={touched && error}
        style={inputMaxWidthPx ? { maxWidth: inputMaxWidthPx } : null}
      />
      {children}
    </FormGroup>
  );
}

Input.propTypes = {
  placeholder: string,
  input: shape({
    name: string,
    onChange: func.isRequired,
    onBlur: func,
    value: string,
  }),
};
