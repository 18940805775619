import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAccountConfirmationDialog } from 'actions';

import { uiSelector } from 'selectors';
import { WarningModal } from 'components/Modal';
import { updateAccount } from './actions';

function AccountConfirmationDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(uiSelector).isAccountConfirmationDialogShown;

  const handleCancel = () => dispatch(toggleAccountConfirmationDialog());
  const handleOk = () => {
    dispatch(updateAccount());
    dispatch(toggleAccountConfirmationDialog());
  };

  return (
    <WarningModal
      handleOk={handleOk}
      isVisible={isVisible}
      handleCancel={handleCancel}
      header={t('accountConfirmationDialog.title')}
    >
      {t('accountConfirmationDialog.body')}
    </WarningModal>
  );
}

export { AccountConfirmationDialog };
