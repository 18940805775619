import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field } from 'redux-form';
import { Input, TextArea } from 'components/Input';
import { Select } from 'components/Select';
import { isRequired } from 'utils/formValidators';
import { get } from 'lodash';
import { websiteSelector, isConsumerAdminSelector } from 'selectors';
import { Form } from 'components/Form';
import { changeWebsite } from 'actions';
import { api } from 'utils/api';
import { languagesOptions } from 'i18n';
import { ActivityPanel } from './ActivityPanel';

const formName = 'EditCorporateTube';

function LanguageMultiSelectField({ formName }) {
  const { t } = useTranslation();
  const options = languagesOptions.map(({ id, name }) => ({
    id,
    name: t(name),
  }));

  return (
    <Field
      name="languages"
      title={t('languages.label')}
      component={Select}
      isMandatory
      isMulti
      isSearchable
      validate={isRequired}
      options={options}
      formName={formName}
    />
  );
}

export function GlobalGeneralInfo({ formName }) {
  const { t } = useTranslation();

  return (
    <>
      <Field
        name="company"
        title={t('generalInfoForm.corporateTubeCompany')}
        description={t('generalInfoForm.corporateTubeCompanyDescr')}
        component={Input}
        isMandatory
        validate={isRequired}
      />

      <Field
        name="internalName"
        title={t('generalInfoForm.internalName')}
        description={t('generalInfoForm.internalNameDescr')}
        component={Input}
      />

      <LanguageMultiSelectField formName={formName} />

      <Field
        name="name"
        title={t('generalInfoForm.websiteTitle')}
        description={t('generalInfoForm.corporateTubeTitleDescr')}
        component={Input}
        isMandatory
        validate={isRequired}
      />

      <div className="form-group">
        <ActivityPanel />
      </div>

      <Field name="notes" title={t('generalInfoForm.notes')} component={TextArea} />
    </>
  );
}

export function GeneralInfo(props) {
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="generalInfo">
      {isConsumerAdmin ? (
        <>
          <Field
            name="name"
            title={t('generalInfoForm.websiteTitle')}
            description={t('generalInfoForm.corporateTubeTitleDescr')}
            component={Input}
            isMandatory
            validate={isRequired}
          />

          <LanguageMultiSelectField formName={formName} />
        </>
      ) : (
        <GlobalGeneralInfo formName={formName} />
      )}
    </Form>
  );
}

const changeWebsiteApi = (id) => (dispatch) => (data) => {
  return api.patch(`/websites/${id}`, data).then((payload) => {
    dispatch(initialize(formName, payload));
    dispatch(changeWebsite(payload));
  });
};

const mapStateToProps = (state) => ({
  website: websiteSelector(state),
  formName,
});

const mapDispatchToProps = {
  changeWebsiteApi,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { company, name, internalName, notes, languages } = stateProps.website || {
    languages: ['en', 'de'],
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initialValues: {
      company,
      name,
      internalName,
      notes,
      languages,
    },
    onSubmit: dispatchProps.changeWebsiteApi(get(stateProps.website, 'id')),
  };
};

export const GeneralInfoDecorated = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(GeneralInfo);
