export const TOGGLE_CREATE_CT_MODAL = '@@ct-admin/TOGGLE_CREATE_CT_MODAL';
export const TOGGLE_DELETE_CT_MODAL = '@@ct-admin/TOGGLE_DELETE_CT_MODAL';
export const LOAD_WEBSITES = '@@ct-admin/LOAD_WEBSITES';
export const DELETE_WEBSITE = '@@ct-admin/DELETE_WEBSITE';
export const CHANGE_WEBSITE = '@@ct-admin/CHANGE_WEBSITE';
export const ADD_WEBSITE = '@@ct-admin/ADD_WEBSITE';
export const SHOW_NOTIFICATION = '@@ct-admin/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = '@@ct-admin/HIDE_NOTIFICATION';
export const APP_CONFIG_LOADED = '@@ct-admin/APP_CONFIG_LOADED';
export const SET_FILTER_STATUSES = '@@ct-admin/SET_FILTER_STATUSES';
export const SET_FILTER_ACCESS_LEVELS = '@@ct-admin/SET_FILTER_ACCESS_LEVELS';
export const SET_FILTER_KEYWORD = '@@ct-admin/SET_FILTER_KEYWORD';
export const SET_CORPORATE_TUBES_SORTING = '@@ct-admin/SET_CORPORATE_TUBES_SORTING';
export const TOGGLE_ACCOUNT_CONFIRMATION_DIALOG = '@@ct-admin/TOGGLE_ACCOUNT_CONFIRMATION_DIALOG';
export const ADD_TOGGLES = '@@ct-admin/ADD_TOGGLES';
export const PLAYERS_LOADED = '@@ct-admin/PLAYERS_LOADED';
export const CHANNELS_LOADED = '@@ct-admin/CHANNELS_LOADED';
export const FONTS_LOADED = '@@ct-admin/FONTS_LOADED';
export const FONT_DELETE = '@@ct-admin/FONT_DELETE';
export const FONT_ADD = '@@ct-admin/FONT_ADD';
export const CLEAR_CACHE = '@@ct-admin/CLEAR_CACHE';

export const toggleAccountConfirmationDialog = () => ({
  type: TOGGLE_ACCOUNT_CONFIRMATION_DIALOG,
});

export const toggleCreateCTModal = () => ({
  type: TOGGLE_CREATE_CT_MODAL,
});

export const toggleDeleteCTModal = () => ({
  type: TOGGLE_DELETE_CT_MODAL,
});

export const loadWebsites = (payload) => ({
  type: LOAD_WEBSITES,
  payload,
});

export const changeWebsite = (payload) => ({
  type: CHANGE_WEBSITE,
  payload,
});

export const addWebsite = (payload) => ({
  type: ADD_WEBSITE,
  payload,
});

export const deleteWebsite = (websiteId) => ({
  type: DELETE_WEBSITE,
  payload: { websiteId },
});

export const showNotification = ({ message, timeout, type }) => ({
  type: SHOW_NOTIFICATION,
  payload: { message, timeout, type },
});

export const hideNotification = ({ id }) => ({
  type: HIDE_NOTIFICATION,
  payload: { id },
});

export const addToggles = (payload) => ({
  type: ADD_TOGGLES,
  payload,
});

export const clearCache = (payload) => ({
  type: CLEAR_CACHE,
  payload,
});
