export const routes = {
  websites: '/ct',
  website: '/ct/:id',
  general: '/ct/:id/general',
  content: '/ct/:id/content',
  security: '/ct/:id/security',
  features: '/ct/:id/features',
  global: '/ct/:id/global',
  typography: '/ct/:id/typography',
  header: '/ct/:id/header',
  navigation: '/ct/:id/navigation',
  contentArea: '/ct/:id/content-area',
  footer: '/ct/:id/footer',
  miscellaneous: '/ct/:id/miscellaneous',
};
