import React from 'react';
import { NavigationChannelsDecorated } from 'pages/Content/NavigationChannels';
import { HomePageChannelsDecorated } from 'pages/Content/HomePageChannels';
import { VideoMetadataDecorated } from './VideoMetadata';
import { VideoListMetadataDecorated } from './VideoListMetadata';
import { ChannelPageChannelsDecorated } from './ChannelPageChannels';

export function Content() {
  return (
    <>
      <NavigationChannelsDecorated />
      <HomePageChannelsDecorated />
      <ChannelPageChannelsDecorated />
      <VideoMetadataDecorated />
      <VideoListMetadataDecorated />
    </>
  );
}
