import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { get } from 'lodash';

import { LocalStoragePlugin } from './LocalStoragePlugin';

import en from './en.json';
import de from './de.json';

const i18nOptions = {
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    'de-DE': {
      translation: de,
    },
  },
  fallbackLng: 'en',
  keySeparator: '.', // we use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

export const getCurrentLanguage = () => i18n.language;

export const initI18n = () =>
  i18n
    .use(LocalStoragePlugin)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ...i18nOptions,
      localStorage: {
        lookupName: 'ct-admin-lng',
      },
    });

export const initI18nConsumer = (language) => {
  // if its get more complicate, write a plugin for the logic
  const isSupportedLng = !!get(i18nOptions.resources, language);

  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ...i18nOptions,
      lng: isSupportedLng ? language : i18nOptions.fallbackLng, // we have no detection, so we have to set it
    });
};

export const languagesOptions = [
  { id: 'en', name: 'languages.en' },
  { id: 'de', name: 'languages.de' },
  { id: 'fr', name: 'languages.fr' },
  { id: 'it', name: 'languages.it' },
];
