import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { ColorPicker } from 'components/ColorPicker';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { websiteSelector } from 'selectors';
import { isColor } from 'utils/formValidators';
import { initialSassValuesSelector, nextCustomStylesSelector } from '../selectors';

const formName = 'DesignOtherComponents';

export function OtherComponents(props) {
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="design.otherComponents.title">
      <span className="section-title">{t('design.otherComponents.pagination')}</span>
      <Field
        name="$paginationHoverColor"
        title={t('design.otherComponents.backgroundColorOnHover')}
        inputMaxWidthPx={200}
        component={ColorPicker}
        validate={[isColor]}
      />

      <span className="section-title">{t('design.otherComponents.progressBar')}</span>
      <Field
        name="$uploadProgressColor"
        title={t('design.otherComponents.barColor')}
        inputMaxWidthPx={200}
        component={ColorPicker}
        validate={[isColor]}
      />
    </Form>
  );
}

const getInitialValuesSelector = (state) =>
  initialSassValuesSelector(state, {
    pickBy: ['$paginationHoverColor', '$uploadProgressColor'],
  });

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const customStyles = nextCustomStylesSelector(getState(), { formValues });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, getInitialValuesSelector(getState())));
    });
};

export const OtherComponentsDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      initialValues: getInitialValuesSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: stateProps.initialValues,
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(OtherComponents);
