import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field, getFormValues } from 'redux-form';
import { Input } from 'components/Input';
import { renderCheckInput } from 'utils/renderCheckInput';
import { isRequired, isCommaSeparatedEmailList, isAtLeastOneChecked, splitStrings } from 'utils/formValidators';
import { get, size } from 'lodash';
import { websiteSelector, isConsumerAdminSelector } from 'selectors';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { CheckboxGroup } from 'components/CheckboxGroup';

const formName = 'ReportVideoFeature';

export function ReportVideoFeature({ formValues, isConsumerAdmin, ...props }) {
  const { t } = useTranslation();

  const isReportVideoFeatureEnabled = get(formValues, 'enabled', false);
  const isEmailFieldSet = Boolean(get(formValues, 'emails'));
  const isLanguagesFieldSet = Boolean(size(get(formValues, 'languages')));

  const emailsFieldValidator =
    isReportVideoFeatureEnabled || isLanguagesFieldSet
      ? [isRequired, isCommaSeparatedEmailList]
      : isCommaSeparatedEmailList;

  const toggleTitle = isReportVideoFeatureEnabled
    ? t('features.reportVideo.report-video-toggle.enabled')
    : t('features.reportVideo.report-video-toggle.disabled');

  return (
    <Form {...props} formTitle="features.reportVideo.title">
      {!isConsumerAdmin && <Field name="enabled" type="switch" title={toggleTitle} component={renderCheckInput} />}

      <Field
        name="emails"
        title={t('features.reportVideo.emails.label')}
        description={t('features.reportVideo.emails.description')}
        component={Input}
        isMandatory={isReportVideoFeatureEnabled || isLanguagesFieldSet}
        validate={emailsFieldValidator}
      />

      <Field
        name="languages"
        title={t('features.reportVideo.languages.label')}
        component={CheckboxGroup}
        isMandatory={isEmailFieldSet}
        validate={isEmailFieldSet ? isAtLeastOneChecked : null}
        options={supportedLanguages}
        translateOption={(opt) => t(`features.reportVideo.languages.${opt}`)}
      />
    </Form>
  );
}

const updateWebsite =
  (id) =>
  (dispatch) =>
  ({ enabled, emails, languages }) => {
    const reportVideoFeature = {
      enabled,
      notification: {
        emails: splitStrings(emails),
        languages,
      },
    };

    return api.patch(`/websites/${id}`, { reportVideoFeature }).then((payload) => {
      dispatch(initialize(formName, getInitialValues(payload.reportVideoFeature)));
      dispatch(changeWebsite(payload));
    });
  };

const supportedLanguages = ['en', 'de'];
const joinEmails = (emails = []) => emails.join(', ');

const getInitialValues = (commentsFeature) => {
  const enabled = get(commentsFeature, 'enabled', false);
  const notification = get(commentsFeature, 'notification', {});

  return {
    enabled,
    emails: joinEmails(notification.emails),
    languages: notification.languages,
  };
};

export const ReportVideoFeatureDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formValues: getFormValues(formName)(state),
      isConsumerAdmin: isConsumerAdminSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const reportVideoFeature = get(stateProps.website, 'reportVideoFeature');

      const { formValues, isConsumerAdmin } = stateProps;
      const initialValues = getInitialValues(reportVideoFeature);

      return {
        formName,
        isConsumerAdmin,
        formValues,
        initialValues,
        onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
      };
    }
  ),
  reduxForm({
    form: formName,
    touchOnChange: true,
    enableReinitialize: true,
  })
)(ReportVideoFeature);
