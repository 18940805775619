import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import { get } from 'lodash';

import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { ConfirmationModal } from 'components/Modal';
import { changeWebsite } from 'actions';
import { websiteSelector } from 'selectors';
import { api } from 'utils/api';
import { renderCheckInput } from 'utils/renderCheckInput';

const formName = 'ConsentConfig';

export function ConsentConfiguration(props) {
  const { t } = useTranslation();
  const [isConfirmationShown, showConfirmation] = useState(false);
  const { formValues, initialValues, handleSubmit, change } = props;
  const isEnabled = get(formValues, 'enabled', true);

  const toggleTitle = isEnabled
    ? t('security.consentConfiguration.toggle.enabled')
    : t('security.consentConfiguration.toggle.disabled');

  const handleConfirm = () => {
    handleSubmit();
    showConfirmation(false);
  };

  const handleCancel = () => {
    showConfirmation(false);
    change('enabled', true);
  };

  const handleOnSubmitMine = (event) => {
    event.preventDefault();

    if (initialValues.enabled && !isEnabled) {
      showConfirmation(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <Form
        disabled={isConfirmationShown}
        {...props}
        handleSubmit={handleOnSubmitMine}
        formTitle="security.consentConfiguration.header"
      >
        <Field name="enabled" type="switch" title={toggleTitle} component={renderCheckInput} />

        <Field
          name="settingsId"
          title={t('security.consentConfiguration.usercentrics.label')}
          description={t('security.consentConfiguration.usercentrics.placeholder')}
          component={Input}
          isMandatory={isEnabled}
        />
      </Form>

      {isConfirmationShown && (
        <ConfirmationModal
          primaryButtonLabel="confirm"
          handleOk={handleConfirm}
          isVisible
          handleCancel={handleCancel}
          header={t('security.consentConfiguration.confirmationModal.header')}
        >
          {t('security.consentConfiguration.confirmationModal.description')}
        </ConfirmationModal>
      )}
    </>
  );
}

const updateWebsite =
  (id) =>
  (dispatch) =>
  async ({ enabled, settingsId }) => {
    const consentConfig = {
      enabled,
      settingsId,
    };

    const payload = await api.patch(`/websites/${id}`, { consentConfig });
    const initialValues = getInitialValues(payload);

    dispatch(initialize(formName, initialValues));
    dispatch(changeWebsite(payload));
  };

const getInitialValues = (website) => {
  const consentConfig = get(website, 'consentConfig', {});
  const enabled = get(consentConfig, 'enabled', true);
  return {
    enabled,
    settingsId: consentConfig.settingsId,
  };
};

export const ConsentConfigurationDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formValues: getFormValues(formName)(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const { formValues, website } = stateProps;
      const initialValues = getInitialValues(website);

      return {
        formName,
        formValues,
        initialValues,
        onSubmit: dispatchProps.updateWebsite(get(website, 'id')),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(ConsentConfiguration);
