import { get } from 'lodash';

import { CtTokenRepository } from 'utils/ct-token.repository';
import { config } from 'config';
import { getNewRelicAgent } from 'newRelicAgent';

export const extractTokenPayload = (ctToken = CtTokenRepository.fetch()) => {
  if (!ctToken) {
    return null;
  }

  try {
    return JSON.parse(window.atob(ctToken.split('.')[1]));
  } catch (error) {
    getNewRelicAgent().noticeError(error);
    return null;
  }
};

export const isCtTokenExpired = (ctTokenPayload) => {
  if (ctTokenPayload === null) {
    return true;
  }
  const secBeforeTokenExpires = config.ctTokenCheckInterval;
  const currentTime = Math.ceil(Date.now() / 1000);

  const expiryTime = Number(get(ctTokenPayload, 'exp', 0));
  const expiryRatio = (expiryTime - currentTime) / secBeforeTokenExpires;

  return expiryRatio <= 0;
};

export const isRawCtTokenExpired = (ctToken) => isCtTokenExpired(extractTokenPayload(ctToken));
