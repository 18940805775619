import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Button } from 'components/Button';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { WarningModal } from 'components/Modal';
import './index.css';

export function FileActions({ fileName, onDownload, onDelete }) {
  const { t } = useTranslation();
  const [isConfirmationModalVisible, showConfirmationModal] = useState();

  const handleDelete = () => {
    showConfirmationModal(true);
  };

  const handleCancel = () => {
    showConfirmationModal(false);
  };

  const handleOk = () => {
    showConfirmationModal(false);
    onDelete();
  };

  return (
    <>
      <InputGroup>
        <Form.Control data-e2e="fileUploadTitle" value={fileName} disabled />
        <DropdownButton data-e2e="fileUploadDropdownButton" alignRight as={InputGroup.Append} title="">
          <Dropdown.Item as="button" type="button" onClick={onDownload}>
            {t('fileUploader.download')}
          </Dropdown.Item>
          <Dropdown.Item as="button" type="button" onClick={handleDelete} data-e2e="fileUploadRemoveButton">
            {t('fileUploader.delete')}
          </Dropdown.Item>
        </DropdownButton>
      </InputGroup>
      <WarningModal
        isVisible={isConfirmationModalVisible}
        primaryButtonLabel="fileUploader.deleteConfirmationModal.primaryButtonLabel"
        handleOk={handleOk}
        handleCancel={handleCancel}
        header={t('fileUploader.deleteConfirmationModal.header')}
      >
        {t('fileUploader.deleteConfirmationModal.body', { fileName })}
      </WarningModal>
    </>
  );
}

export function FileBrowse({ onFileChosen, acceptedFileTypes, isLoading }) {
  const { t } = useTranslation();
  const fileEl = useRef();

  const onChange = (e) => {
    const file = get(e.target, 'files[0]');

    if (file && onFileChosen) onFileChosen(file);
  };

  return (
    <>
      <Button
        variant="secondary"
        className="file-uploader__button"
        data-e2e="fileUploadButton"
        isLoading={isLoading}
        onClick={() => fileEl.current.click()}
      >
        {!isLoading && t('fileUploader.buttonLabel')}
      </Button>
      <input
        type="file"
        ref={fileEl}
        className="file-uploader__input"
        onChange={onChange}
        accept={acceptedFileTypes}
        title={t('fileUploader.title')}
      />
    </>
  );
}

export function FileUpload({ apiUrl, fileURL, title, description, acceptedFileTypes, fileSizeLimit }) {
  const [isLoading, setLoading] = useState();
  const [isDeleting, setDeleting] = useState();
  const [error, setError] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileName = fileURL && fileURL.split('/').pop().split('#')[0].split('?')[0];

  const handleUpload = (file) => {
    if (fileSizeLimit && file.size > fileSizeLimit) {
      return setError(
        t('fileUploader.fileSizeExceededError', {
          fileSizeLimit: fileSizeLimit >> 10,
        })
      );
    }

    setError(null);
    setLoading(true);

    return api
      .post(apiUrl, { file }, false)
      .then((payload) => {
        dispatch(changeWebsite(payload));
      })
      .catch(({ errors }) => {
        setError(errors.filename);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = fileURL;
    a.download = fileURL; // not supported by IE11 (file be openned in a new tab)
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDelete = () => {
    setDeleting(true); // hide BE slowness emulating optimistic delete
    return api
      .delete(apiUrl)
      .then((payload) => {
        dispatch(changeWebsite(payload));
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  return (
    <div>
      {title && (
        <div>
          <Form.Label className="title">{title}</Form.Label>
        </div>
      )}
      {fileURL && !isDeleting ? (
        <FileActions fileName={fileName} onDownload={handleDownload} onDelete={handleDelete} />
      ) : (
        <FileBrowse onFileChosen={handleUpload} acceptedFileTypes={acceptedFileTypes} isLoading={isLoading} />
      )}
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
      {description && <Form.Text className="text-muted">{description}</Form.Text>}
    </div>
  );
}

FileUpload.propTypes = {
  acceptedFileTypes: string.isRequired,
  apiUrl: string.isRequired,
  fileURL: string,
};
