import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { WebsiteAccessLevel } from 'api/websiteAccessLevel';
import { SET_FILTER_ACCESS_LEVELS } from 'actions';
import { selectedAccessLevelsSelector } from 'selectors';

import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { ToggleButton } from 'components/ToggleButton';

const buttons = [
  {
    id: WebsiteAccessLevel.PROTECTED,
    variant: 'access-protected',
  },
  {
    id: WebsiteAccessLevel.PUBLIC,
    variant: 'access-public',
  },
];

const setSelectedAccessLevels = (payload) => ({
  type: SET_FILTER_ACCESS_LEVELS,
  payload,
});

export function AccessFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAccessLevels = useSelector(selectedAccessLevelsSelector);

  return (
    <ToggleButtonGroup
      className="status-filter-group"
      type="checkbox"
      value={selectedAccessLevels}
      onChange={(payload) => dispatch(setSelectedAccessLevels(payload))}
    >
      {map(buttons, (btn) => (
        <ToggleButton key={btn.id} value={btn.id} variant={btn.variant} data-e2e={`status-filter-btn-${btn.id}`}>
          {t(btn.id)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
