import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { initAuth } from 'auth';

import { initToggles } from './feature.toggles';
import { initI18n } from './i18n';
import { App } from './pages/App';
import { configureStore } from './store/configureStore';
import { AuthenticationErrorPage } from './pages/AuthenticationErrorPage';

const container = document.getElementById('cta');
const cta = createRoot(container);

const renderGlobalApp = () => {
  const history = createBrowserHistory();
  const store = configureStore(history);
  initToggles(store.dispatch);

  cta.render(<App store={store} history={history} />);
};

const initAuthenticationErrorApp = () => {
  cta.render(<AuthenticationErrorPage />);
};

export const initGlobalApp = () => {
  initI18n();

  initAuth()
    .then(() => renderGlobalApp())
    .catch(() => initAuthenticationErrorApp());
};
