import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, reduce } from 'lodash';

import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { websiteSelector, appConfigSelector } from 'selectors';
import { isColor, isPixel, isPositiveNumber } from 'utils/formValidators';
import { ColorPicker } from 'components/ColorPicker';
import { Form } from 'components/Form';
import { BorderInput } from 'components/BorderInput';
import { Input } from 'components/Input';

import { initialSassValuesSelector, nextCustomStylesSelector } from '../selectors';
import '../index.css';

const formName = 'DesignContentArea';

export function ContentArea(props) {
  const { t } = useTranslation();
  const { initialValues } = props;
  const { $pageSectionBorderWidth: pageSectionBorderDisabled } = initialValues;

  return (
    <Form {...props} formTitle="design.contentArea.title">
      <Field
        name="$pageBgColor"
        title={t('design.contentArea.pageBgColor.title')}
        inputMaxWidthPx={200}
        component={ColorPicker}
        validate={[isColor]}
      />

      <Field
        name="$pageSectionBgColor"
        title={t('design.contentArea.pageSectionBgColor.title')}
        inputMaxWidthPx={200}
        component={ColorPicker}
        validate={[isColor]}
      />

      <BorderInput
        title={t('design.contentArea.pageSectionBorder.title')}
        checkboxValue={pageSectionBorderDisabled}
        widthVariableName="$pageSectionBorderWidth"
        colorVariableName="$pageSectionBorderColor"
      />

      <Field
        name="$pageSectionBorderRadius"
        title={t('design.contentArea.pageSectionBorderRadius.title')}
        component={Input}
        inputMaxWidthPx={200}
        validate={[isPositiveNumber, isPixel]}
        description={t('design.contentArea.pageSectionBorderRadius.description')}
      />
    </Form>
  );
}

const getInitialValuesSelector = (state) =>
  initialSassValuesSelector(state, {
    pickBy: [
      '$pageBgColor',
      '$pageSectionBgColor',
      '$pageSectionBorderColor',
      '$pageSectionBorderWidth',
      '$pageSectionBorderRadius',
    ],
  });

const normalizeBorderToBool = (vars) => ({
  ...vars,
  $pageSectionBorderWidth: vars.$pageSectionBorderWidth !== '0',
});

const getNormalizedInitialValuesSelector = (state) => normalizeBorderToBool(getInitialValuesSelector(state));

const normalizeBorderValuesToPx = ({ $pageSectionBorderWidth }, state) => {
  const defaultValues = get(appConfigSelector(state), 'defValues.website.sassVariables');

  return reduce(
    { $pageSectionBorderWidth },
    (acc, enabled, key) => {
      acc[key] = enabled ? defaultValues[key] : '0';
      return acc;
    },
    {}
  );
};

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const state = getState();
  const normalizedBorders = normalizeBorderValuesToPx(formValues, state);

  const customStyles = nextCustomStylesSelector(getState(), {
    formValues: {
      ...formValues,
      ...normalizedBorders,
    },
  });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, getNormalizedInitialValuesSelector(getState())));
    });
};

export const ContentAreaDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      initialValues: getNormalizedInitialValuesSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: stateProps.initialValues,
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(ContentArea);
