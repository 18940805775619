import { assign, reduce, keys, startsWith, omit } from 'lodash';
import { fetch } from 'whatwg-fetch';
import { addToggles } from 'actions';
import { getUrlParams } from 'utils/urlParams';

const urls = {
  qa: 'https://corporatetubeqa.blob.core.windows.net',
  prod: 'https://ct-assets-prod.corporate.tube',
};

const url = urls[process.env.REACT_APP_RELEASE_CHANNEL || 'qa'];

const loadToggles = (url) => {
  return fetch(`${url}/feature-toggles/admin.json?${Date.now()}`)
    .then((response) => response.text())
    .then((text) => text && JSON.parse(text));
};

const urlParams = getUrlParams();
const toggles = reduce(
  keys(urlParams),
  (acc, item) => {
    if (startsWith(item, 'ft_')) {
      acc[item.substring(3)] = urlParams[item] === 'true';
    }
    return acc;
  },
  {}
);

const initToggles = (dispatch) => {
  if (url) {
    loadToggles(url)
      .then((resp) => {
        assign(toggles, omit(resp, keys(toggles)));
        dispatch(addToggles(toggles));
      })
      .catch(() => {
        // no feature toggles available.
      });
  }
};

export { toggles, initToggles };
