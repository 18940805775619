import React from 'react';
import { components } from 'react-select';

export function ValueContainer({ children, ...props }) {
  if (children[0] && children[0].length) {
    const newChildren = children[0].map((child) =>
      React.cloneElement(child, {
        key: child.props.children,
      })
    );

    newChildren.forEach((newChild, index) => {
      children[0][index] = newChild;
    });
  }

  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
}
