export const getUrlParams = () => {
  const result = {};
  let params = window.location.search;
  params = params.substr(1);
  const queryParamArray = params.split('&amp;');
  queryParamArray.forEach((queryParam) => {
    const item = queryParam.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};
