import { merge } from 'lodash';
import { APP_CONFIG_LOADED } from 'actions';

const initialState = {};

export const appConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_CONFIG_LOADED:
      return merge({}, state, action.payload);

    default:
      return state;
  }
};
