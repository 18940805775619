import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Form } from 'components/Form';
import { Select } from 'components/Select';
import { isRequired } from 'utils/formValidators';
import { api } from 'utils/api';
import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';
import { loadChannelOptions, channelsSelector, replaceRootChannelName } from '../channelsHelper';

const formName = 'ContentNavigationChannels';

export function NavigationChannels({ channels, loadChannelOptions, ...props }) {
  const { t } = useTranslation();
  const channelIds = get(props.initialValues, 'navigation.channelIds', []);

  useEffect(() => {
    if (channelIds.length) {
      loadChannelOptions();
    }
  }, [channelIds.length, loadChannelOptions]);

  const channelOptions = replaceRootChannelName(channels, t);

  return (
    <Form {...props} formTitle="content.navigation.header">
      <div className="form-group">
        <Field
          name="navigation.channelIds"
          title={t('content.navigation.navigationChannelIds.label')}
          placeholder={t('content.navigation.navigationChannelIds.placeholder')}
          description={t('content.navigation.navigationChannelIds.hint')}
          component={Select}
          isMandatory
          isMulti
          isSearchable
          validate={isRequired}
          options={channelOptions}
          onInputFocus={loadChannelOptions}
          formName={formName}
        >
          <div className="form-check">
            <Field
              className="form-check-input"
              name="navigation.useAsRoot"
              id="navigationChannelIdsUseAsRoot"
              data-e2e="navigation.useAsRoot"
              type="checkbox"
              component="input"
            />
            <label htmlFor="navigationChannelIdsUseAsRoot" className="form-check-label channels_form-check-label">
              {t('content.navigation.navigationChannelIdsUseAsRoot.label')}
            </label>
          </div>
        </Field>
      </div>
    </Form>
  );
}

const getInitialFormValues = (website) => ({
  navigation: {
    channelIds: [],
    useAsRoot: false,
  },
  ...get(website, 'content'),
});

const saveChannels = (website) => (dispatch) => (formData) => {
  const content = get(website, 'content');
  const homeVideoListSettings = get(website, 'homeVideoListSettings');
  const { navigation } = formData;

  return api
    .patch(`/websites/${website.id}`, {
      content: {
        ...content,
        navigation,
      },
      homeVideoListSettings,
    })
    .then((websitePayload) => {
      dispatch(initialize(formName, getInitialFormValues(websitePayload)));
      dispatch(changeWebsite(websitePayload));
    });
};

export const NavigationChannelsDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      channels: channelsSelector(state),
      formName,
    }),
    {
      saveChannels,
      loadChannelOptions,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: getInitialFormValues(stateProps.website),
        channels: stateProps.channels,
        loadChannelOptions: dispatchProps.loadChannelOptions(websiteId, stateProps.channels),
        onSubmit: dispatchProps.saveChannels(stateProps.website),
      };
    }
  ),
  reduxForm({
    form: formName,
    touchOnChange: true,
    enableReinitialize: true,
  })
)(NavigationChannels);
