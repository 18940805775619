import React from 'react';

export function AuthenticationErrorPage() {
  return (
    <div style={{ margin: '30px' }} data-e2e="authErrorPage">
      <h3> Authentication error </h3>
      <a href="/"> Go back to homepage </a>
    </div>
  );
}
