import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import { isColor } from 'utils/formValidators';
import { ColorPicker } from 'components/ColorPicker';
import { renderCheckInput } from 'utils/renderCheckInput';

import './index.css';

export function BorderInput({ title, checkboxValue, widthVariableName, colorVariableName }) {
  const [disableColorPiker, toggleDisableColorPiker] = useState(!checkboxValue);

  return (
    <div className="form-group">
      {title && <Form.Label className="title">{title}</Form.Label>}
      <div className="border-input__children">
        <Field
          name={widthVariableName}
          type="checkbox"
          component={renderCheckInput}
          onChange={() => toggleDisableColorPiker(!disableColorPiker)}
        />
        <Field
          name={colorVariableName}
          inputMaxWidthPx={200}
          component={ColorPicker}
          disabled={disableColorPiker}
          validate={[isColor]}
        />
      </div>
    </div>
  );
}

BorderInput.propTypes = {
  title: string,
  widthVariableName: string,
  colorVariableName: string,
  checkboxValue: bool,
};
