import { websiteSelector, appConfigSelector } from 'selectors';
import { reduce, get, pick } from 'lodash';

export const initialSassValuesSelector = (state, { pickBy }) => {
  const currentValues = get(websiteSelector(state), 'customStyles.sassVariables');
  const defaultValues = get(appConfigSelector(state), 'defValues.website.sassVariables');

  return pick(
    {
      ...defaultValues,
      ...currentValues,
    },
    pickBy
  );
};

export const nextCustomStylesSelector = (state, { formValues }) => {
  const defaultValues = get(appConfigSelector(state), 'defValues.website.sassVariables');

  const currentValues = get(websiteSelector(state), 'customStyles.sassVariables');

  const sassVariables = reduce(
    {
      ...currentValues,
      ...formValues,
    },
    (result, value, key) => {
      if (value !== get(defaultValues, key)) {
        result[key] = value;
      }
      return result;
    },
    {}
  );

  return {
    sassVariables,
    overrides: get(websiteSelector(state), 'customStyles.overrides'),
  };
};
