import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { websiteStatus } from 'api/websiteStatus';
import { selectedStatusesSelector } from 'selectors';
import { SET_FILTER_STATUSES } from 'actions';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { ToggleButton } from 'components/ToggleButton';

const buttons = [
  {
    id: websiteStatus.DEMO,
    variant: 'status-demo',
  },
  {
    id: websiteStatus.CREATION,
    variant: 'status-creation',
  },
  {
    id: websiteStatus.LIVE,
    variant: 'status-live',
  },
  {
    id: websiteStatus.DEACTIVATED,
    variant: 'status-deactivated',
  },
];

const setSelectedStatuses = (payload) => ({
  type: SET_FILTER_STATUSES,
  payload,
});

export function StatusFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedStatuses = useSelector(selectedStatusesSelector);

  return (
    <ToggleButtonGroup
      className="status-filter-group"
      type="checkbox"
      value={selectedStatuses}
      onChange={(payload) => dispatch(setSelectedStatuses(payload))}
    >
      {map(buttons, (btn) => (
        <ToggleButton key={btn.id} value={btn.id} variant={btn.variant} data-e2e={`status-filter-btn-${btn.id}`}>
          {t(btn.id)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
