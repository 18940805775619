import { fetch } from 'whatwg-fetch';
import { CtTokenRepository } from 'utils/ct-token.repository';
import { isRawCtTokenExpired } from 'utils/token';
import { updateToken } from 'auth';
import { getCurrentLanguage } from 'i18n';

export const BASE_URL = process.env.REACT_APP_API_URL || '/api';

const deleteTokenAndReloadPage = () => {
  CtTokenRepository.delete();
  window.location.reload(true);
};

const tokenExpiredErrorHandle = (error) => {
  if (error.status === 403 && error.json && error.json.message === 'Expired token') {
    deleteTokenAndReloadPage();
  }
};

/**
 * TODO - convert to async/await
 * @param {string} endpoint
 * @param {*} options - fetch() api options object
 * @param {string} base - base url
 * @return {Promise<T>}
 */
export const doFetch = (endpoint, options, base = BASE_URL) =>
  fetch(base + endpoint, options)
    // Await the promise of json(), and return both the raw response promise and the JSON promise
    .then((response) =>
      response.text().then((text) => {
        try {
          return {
            json: JSON.parse(text || '{}'),
            response,
          };
        } catch (e) {
          return Promise.reject(new SyntaxError(`JSON parse error of: ${text}`));
        }
      })
    )
    .then(({ json, response }) => {
      // if response with error update response object with response body and reject
      // .catch() will receive that object as error
      const { status, ok } = response;

      if (status === 204 || (status >= 400 && !ok)) {
        response.json = json;
        return Promise.reject(response);
      }

      // all good return json response
      return json;
    })
    .catch((error) => {
      tokenExpiredErrorHandle(error);
      throw error;
    });

/**
 * @param {string|null} token - CT JWT Token
 * @param {string} endpoint
 * @param {*} options - fetch() api options object
 * @return {Promise<T>}
 */
export const preFetch = async (token, endpoint, options = {}) => {
  const isLoginRequest = endpoint === '/auth/login';
  const isProtectedRequest = token !== null && !isLoginRequest;

  if (isProtectedRequest) {
    const bearer = isRawCtTokenExpired(token) ? await updateToken().catch(() => deleteTokenAndReloadPage()) : token;

    options.headers = {
      ...options.headers,
      ...{
        Authorization: `Bearer ${bearer}`,
        Accept: 'application/json',
        'Accept-Language': getCurrentLanguage(),
      },
    };
  }

  return doFetch(endpoint, options);
};

/**
 * Helper function to perform API requests
 *
 * @param {String}      endpoint Endpoint URL (without / at the start) to request
 * @param {String}      method   HTTP method to make the request with (defaults to 'GET')
 * @param {Object|null} data     Optional POST/PUT data to append to the request body
 * @param {boolean}     json     request body format
 * @returns {Promise<T>}
 */
export const callApi = (endpoint, method = 'GET', data = null, json = true) => {
  const token = CtTokenRepository.fetch();

  const options = {
    method,
    headers: {},
    body: null,
  };

  if (data !== null) {
    if (!json) {
      const body = new FormData();

      Object.keys(data).forEach((key) => {
        const value = data[key];

        if (value instanceof window.FileList) {
          Array.from(value).forEach((file) => {
            body.append(`${key}[]`, file, file.name);
          });
        } else {
          body.append(key, value);
        }
      });

      options.body = body;
    } else {
      options.headers['Content-Type'] = 'application/json';

      options.body = JSON.stringify(data);
    }
  }

  return preFetch(token, endpoint, options);
};
