import { get, find, some } from 'lodash';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

export const uiSelector = (state) => state.ui;
export const routerSelector = (state) => state.router;
export const websitesSelector = (state) => state.websites;
export const vmproSelector = (state) => state.vmpro;

export const websiteIdSelector = createSelector(routerSelector, (router) => {
  const path = get(router, 'location.pathname');
  const match = matchPath(path, {
    path: '/ct/:id',
  });
  return get(match, 'params.id');
});

export const websiteSelector = createSelector(
  websiteIdSelector,
  websitesSelector,
  (websiteId, websites) => websiteId && find(websites, (website) => website.id === Number(websiteId))
);

export const appConfigSelector = (state) => state.appConfig;

export const notificationsSelector = (state) => state.ui.notifications;
export const selectedStatusesSelector = (state) => state.ui.selectedStatuses;
export const selectedAccessLevelsSelector = (state) => state.ui.setSelectedAccessLevels;
export const filterCorporateTubesBySelector = (state) => state.ui.filterCorporateTubesBy;
export const sortCorporateTubesBySelector = (state) => get(state.ui, 'sortCorporateTubesBy', {});

export const isConsumerAdminSelector = (state) => state.isConsumerApp;
export const togglesSelector = (state) => state.ui.toggles;
export const cacheSelector = (state) => state.ui.cache;

export const isNotConsumerAdminSelector = (state) => !isConsumerAdminSelector(state);

export const isCommentFeatureEnabledSelector = createSelector(websiteSelector, (website) =>
  get(website, 'commentFeature.enabled', false)
);

export const isReportVideoFeatureEnabledSelector = createSelector(websiteSelector, (website) =>
  get(website, 'reportVideoFeature.enabled', false)
);

export const isFeatureMenuItemEnabledSelector = createSelector(
  isCommentFeatureEnabledSelector,
  isReportVideoFeatureEnabledSelector,
  (...featuresAreEnabled) => some(featuresAreEnabled)
);

export const showCommentFormSelector = createSelector(
  isConsumerAdminSelector,
  isCommentFeatureEnabledSelector,
  (isConsumerAdmin, isCommentFeatureEnabled) => !isConsumerAdmin || (isConsumerAdmin && isCommentFeatureEnabled)
);

export const showReportVideoFormSelector = createSelector(
  isConsumerAdminSelector,
  isReportVideoFeatureEnabledSelector,
  (isConsumerAdmin, isReportVideoFeatureEnabled) => !isConsumerAdmin || (isConsumerAdmin && isReportVideoFeatureEnabled)
);
