import React from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { get, map, find } from 'lodash';
import './index.css';

export function Dropdown({ options, onSelect, placeholder, disabled, alignRight }) {
  const option = find(options, { isActive: true });
  const title = get(option, 'title') || placeholder;

  return (
    <BootstrapDropdown alignRight={alignRight} onSelect={onSelect}>
      <BootstrapDropdown.Toggle
        className={`shadow-none ${get(option, 'key')}`}
        variant="mi"
        disabled={disabled}
        data-e2e="dropdownToggle"
      >
        {title}
      </BootstrapDropdown.Toggle>

      <BootstrapDropdown.Menu>
        {map(options, (option) => (
          <BootstrapDropdown.Item
            key={option.key}
            eventKey={option.key}
            className={option.key}
            data-e2e={option.key}
            active={option.isActive}
          >
            {option.title}
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
}

Dropdown.defaultProps = {
  alignRight: false,
};
