import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Form } from 'components/Form';
import { Select } from 'components/Select';
import { Input } from 'components/Input/Input';
import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';
import { api } from 'utils/api';
import { channelsSelector } from 'pages/Content/selectors';

import { loadChannelOptions, replaceRootChannelName } from '../channelsHelper';
import './index.css';

const formName = 'ChannelPageChannels';

export function ChannelPageChannels(props) {
  const { channels, loadChannelOptions, initialValues } = props;
  const { t } = useTranslation();
  const { channelIds } = initialValues;

  useEffect(() => {
    if (channelIds.length) {
      loadChannelOptions();
    }
  }, [channelIds.length, loadChannelOptions]);

  const channelOptions = replaceRootChannelName(channels, t);

  return (
    <Form {...props} formTitle="content.channel.header">
      <div className="form-group">
        <Field
          name="channelIds"
          title={t('content.channel.channelPageChannelIds.label')}
          placeholder={t('content.channel.channelPageChannelIds.placeholder')}
          description={t('content.channel.channelPageChannelIds.hint')}
          component={Select}
          isMulti
          isSearchable
          options={channelOptions}
          onInputFocus={loadChannelOptions}
          formName={formName}
        >
          <div className="form-check">
            <Field
              className="form-check-input"
              name="useAsRoot"
              id="channelPageChannelIdsUseAsRoot"
              data-e2e="useAsRoot"
              type="checkbox"
              component="input"
            />
            <label htmlFor="channelPageChannelIdsUseAsRoot" className="form-check-label channels_form-check-label">
              {t('content.channel.channelPageChannelIdsUseAsRoot.label')}
            </label>
          </div>
        </Field>

        <Field name="label" title={t('content.channel.channelListHeadline.title')} component={Input} />
      </div>
    </Form>
  );
}

const getInitialFormValues = (website) => ({
  channelIds: [],
  useAsRoot: false,
  label: '',
  ...get(website, 'content.channelPage'),
});

const saveChannels = (id) => (dispatch, getState) => (formData) => {
  const { channelIds, useAsRoot, label } = formData;
  const content = get(websiteSelector(getState()), 'content');

  return api
    .patch(`/websites/${id}`, {
      content: {
        ...content,
        channelPage: { channelIds, useAsRoot, label },
      },
    })
    .then((websitePayload) => {
      dispatch(initialize(formName, getInitialFormValues(websitePayload)));
      dispatch(changeWebsite(websitePayload));
    });
};

export const ChannelPageChannelsDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      channels: channelsSelector(state),
      formName,
    }),
    {
      saveChannels,
      loadChannelOptions,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: getInitialFormValues(stateProps.website),
        channels: stateProps.channels,
        loadChannelOptions: dispatchProps.loadChannelOptions(websiteId, stateProps.channels),
        onSubmit: dispatchProps.saveChannels(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    touchOnChange: true,
    enableReinitialize: true,
  })
)(ChannelPageChannels);
