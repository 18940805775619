import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, initialize, Field } from 'redux-form';
import { Input } from 'components/Input';
import { isRequired } from 'utils/formValidators';
import { api } from 'utils/api';
import { get } from 'lodash';

import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';

import { Form } from 'components/Form';

const formName = 'EditCorporateTubeHostname';

export function Hostname(props) {
  return (
    <Form {...props} formTitle="hostname">
      <Field name="hostname" component={Input} validate={isRequired} />
    </Form>
  );
}

const changeWebsiteApi = (id) => (dispatch) => (data) => {
  return api.patch(`/websites/${id}`, data).then((payload) => {
    dispatch(initialize(formName, payload));
    dispatch(changeWebsite(payload));
  });
};

export const HostnameDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      changeWebsiteApi,
    },
    (stateProps, dispatchProps, ownProps) => {
      const hostname = get(stateProps.website, 'hostname');
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        initialValues: { hostname },
        onSubmit: dispatchProps.changeWebsiteApi(get(stateProps.website, 'id')),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Hostname);
