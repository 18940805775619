import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { deleteWebsite, toggleDeleteCTModal, showNotification } from 'actions';
import { Buttons } from 'components/Form/Buttons';
import { Modal } from 'components/Modal';
import { uiSelector, websiteSelector } from 'selectors';
import { api } from 'utils/api';
import { NOTIFICATION_TYPES } from 'components/Notifications';

const formName = 'DeleteCorporateTube';

export function DeleteConfirmationModal({ reset, submitting, handleSubmit, show, t, onHide, error, website }) {
  const cancel = () => {
    onHide();
    reset();
  };
  const corporateTube = `${get(website, 'company')} - ${get(website, 'name')}`;

  return (
    <Modal isVisible={show} onHide={cancel} header={t('deleteCorporateTube')}>
      <form onSubmit={handleSubmit}>
        <span className="d-block">{t('deleteConfirmation', { corporateTube })}</span>
        <span className="d-block mb-3">{t('actionCannotBeUndone')}</span>

        {error && <small className="text-danger">{error}</small>}

        <Buttons
          reset={cancel}
          submitting={submitting}
          primaryTitle={t('delete')}
          primaryVariant="danger"
          submittingTitle={t('deleting')}
          secondaryTitle={t('cancel')}
          secondaryDisabled={submitting}
          valid
        />
      </form>
    </Modal>
  );
}

const deleteCorporateTube = (history, website, t) => (dispatch) => () => {
  return api
    .delete(`/websites/${website.id}`)
    .then(() => {
      dispatch(toggleDeleteCTModal());
      history.push(`/`);
      dispatch(deleteWebsite(website.id));

      dispatch(
        showNotification({
          message: `${website.name} ${website.company} ${t('deleteSuccess')}`,
          timeout: 5000,
          type: NOTIFICATION_TYPES.SUCCESS,
        })
      );
    })
    .catch(() => {
      dispatch(toggleDeleteCTModal());
    });
};

export const DeleteConfirmationModalDecorated = compose(
  withTranslation(),
  withRouter,
  connect(
    (state) => ({
      show: uiSelector(state).isDeleteCTModalShown,
      website: websiteSelector(state),
    }),
    {
      onHide: toggleDeleteCTModal,
      deleteCorporateTube,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmit: dispatchProps.deleteCorporateTube(ownProps.history, stateProps.website, ownProps.t),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(DeleteConfirmationModal);
