import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, reduce } from 'lodash';

import { Form } from 'components/Form';
import { ColorPicker } from 'components/ColorPicker';
import { BorderInput } from 'components/BorderInput';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { websiteSelector, appConfigSelector } from 'selectors';
import { isColor } from 'utils/formValidators';
import { initialSassValuesSelector, nextCustomStylesSelector } from '../selectors';

const formName = 'DesignNavigation';

export function Navigation(props) {
  const { t } = useTranslation();
  const { initialValues } = props;
  const { $headerMenuBorderTopWidth: borderTopDisabled, $headerMenuBorderBottomWidth: borderBottomDisabled } =
    initialValues;

  return (
    <Form {...props} formTitle="design.navigation.title">
      <span className="section-title">{t('design.navigation.navBar')}</span>
      <div className="design-form__row-inline flex-wrap">
        <Field
          name="$headerMenuBgColor"
          title={t('design.navigation.navigationBg.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <BorderInput
          title={t('design.navigation.borderTop.title')}
          checkboxValue={borderTopDisabled}
          widthVariableName="$headerMenuBorderTopWidth"
          colorVariableName="$headerMenuBorderTopColor"
        />

        <BorderInput
          title={t('design.navigation.borderBottom.title')}
          checkboxValue={borderBottomDisabled}
          widthVariableName="$headerMenuBorderBottomWidth"
          colorVariableName="$headerMenuBorderBottomColor"
        />
      </div>

      <span className="section-title">{t('design.navigation.elements')}</span>
      <div className="design-form__row-inline flex-wrap">
        <Field
          name="$headerMenuItemColor"
          title={t('design.navigation.menuItemColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerMenuItemHoverColor"
          title={t('design.navigation.menuItemHoverColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerMenuItemHoverBgColor"
          title={t('design.navigation.menuItemHoverBgColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerMenuItemActiveColor"
          title={t('design.navigation.menuItemActiveColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
      </div>

      <span className="section-title section-top-margin">{t('design.navigation.menu')}</span>

      <div className="design-form__row-inline flex-wrap">
        <Field
          name="$headerSubMenuItemColor"
          title={t('design.navigation.subMenuItemColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerSubMenuBgColor"
          title={t('design.navigation.subMenuItemBgColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerSubMenuItemHoverColor"
          title={t('design.navigation.subMenuItemHoverColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerSubMenuItemHoverBgColor"
          title={t('design.navigation.subMenuItemHoverBgColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />

        <Field
          name="$headerSubMenuItemActiveColor"
          title={t('design.navigation.subMenuItemActiveColor.title')}
          inputMaxWidthPx={200}
          component={ColorPicker}
          validate={[isColor]}
        />
      </div>
    </Form>
  );
}

const getInitialValuesSelector = (state) =>
  initialSassValuesSelector(state, {
    pickBy: [
      '$headerMenuBgColor',
      '$headerMenuBorderTopColor',
      '$headerMenuBorderTopWidth',
      '$headerMenuBorderBottomColor',
      '$headerMenuBorderBottomWidth',
      '$headerMenuItemColor',
      '$headerMenuItemHoverColor',
      '$headerMenuItemHoverBgColor',
      '$headerMenuItemActiveColor',
      '$headerSubMenuItemColor',
      '$headerSubMenuBgColor',
      '$headerSubMenuItemHoverColor',
      '$headerSubMenuItemHoverBgColor',
      '$headerSubMenuItemActiveColor',
    ],
  });

const normalizeBorderToBool = (vars) => ({
  ...vars,
  $headerMenuBorderTopWidth: vars.$headerMenuBorderTopWidth !== '0',
  $headerMenuBorderBottomWidth: vars.$headerMenuBorderBottomWidth !== '0',
});

const getNormalizedInitialValuesSelector = (state) => normalizeBorderToBool(getInitialValuesSelector(state));

const normalizeBorderValuesToPx = ({ $headerMenuBorderTopWidth, $headerMenuBorderBottomWidth }, state) => {
  const defaultValues = get(appConfigSelector(state), 'defValues.website.sassVariables');

  return reduce(
    { $headerMenuBorderTopWidth, $headerMenuBorderBottomWidth },
    (acc, curr, key) => {
      acc[key] = curr ? defaultValues[key] : '0';
      return acc;
    },
    {}
  );
};

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const state = getState();
  const normalizedBorders = normalizeBorderValuesToPx(formValues, state);

  const customStyles = nextCustomStylesSelector(getState(), {
    formValues: {
      ...formValues,
      ...normalizedBorders,
    },
  });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, getNormalizedInitialValuesSelector(getState())));
    });
};

export const NavigationDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      initialValues: getNormalizedInitialValuesSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: stateProps.initialValues,
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Navigation);
