import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isConsumerAdminSelector } from 'selectors';

import { GlobalMenu } from './GlobalMenu';
import { ConsumerMenu } from './ConsumerMenu';
import { Website } from './website';
import './index.css';

export function CorporateTubePage() {
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);

  return (
    <div className="corporate-tube-page__container">
      <div className="corporate-tube-page__menu">{isConsumerAdmin ? <ConsumerMenu /> : <GlobalMenu />}</div>

      <div className="corporate-tube-page__sub-page">
        <Route path="/:id" component={Website} />
      </div>
    </div>
  );
}
