import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field, getFormValues } from 'redux-form';
import { renderCheckInput } from 'utils/renderCheckInput';
import { get, reduce } from 'lodash';
import { isConsumerAdminSelector, websiteSelector } from 'selectors';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';

const formName = 'GeneralFeatures';

const featureNames = {
  SAVE_VIDEOS: 'savedVideosFeatureEnabled',
  RATING: 'ratingFeatureEnabled',
  RELATEDVIDEOS: 'relatedVideosFeatureEnabled',
  UPLOAD: 'uploadFeatureEnabled',
  RECORD_VIDEO: 'recordVideoFeatureEnabled',
  RECORD_SCREEN: 'recordScreenFeatureEnabled',
  UPLOAD_PODCAST: 'uploadPodcastFeatureEnabled',
  USE_IQS: 'iqsFeatureEnabled',
};

const getConsumerFeatures = () =>
  Object.values(featureNames).filter((featureName) => featureName !== featureNames.USE_IQS);

function GeneralFeatures(props) {
  const { t } = useTranslation();
  const formValues = useSelector(getFormValues(formName));
  const { hasSsoEnabled, isConsumerAdmin } = props;
  const { uploadFeatureEnabled } = formValues;
  let availableFeatureNames = featureNames;

  const getSsoRequiredHint = () => (hasSsoEnabled ? null : t('features.general.ssoRequiredHint'));
  const getRecordVideoHint = () =>
    !hasSsoEnabled || !uploadFeatureEnabled ? t('features.general.recordVideoHint') : null;

  const getDescription = (featureName) => {
    let description;

    switch (featureName) {
      case featureNames.UPLOAD:
      case featureNames.UPLOAD_PODCAST:
        description = getSsoRequiredHint();
        break;
      case featureNames.RECORD_VIDEO:
      case featureNames.RECORD_SCREEN:
        description = getRecordVideoHint();
        break;
      default:
        description = '';
    }

    return description;
  };

  if (isConsumerAdmin) {
    availableFeatureNames = getConsumerFeatures();
  }

  return (
    <Form {...props} formTitle="features.general.title">
      {Object.values(availableFeatureNames).map((featureName) => {
        const description = getDescription(featureName);

        const customClass =
          featureName === featureNames.RECORD_VIDEO || featureName === featureNames.RECORD_SCREEN
            ? 'custom-checkbox__sub1'
            : null;

        return (
          <Field
            key={featureName}
            name={`${featureName}`}
            type="checkbox"
            customClass={customClass}
            disabled={!!description}
            description={description}
            title={t(`features.general.${featureName}`)}
            component={renderCheckInput}
          />
        );
      })}
    </Form>
  );
}

const updateWebsite = (id) => (dispatch) => (features) => {
  return api.patch(`/websites/${id}`, features).then((payload) => {
    dispatch(initialize(formName, getInitialFormValues(payload)));
    dispatch(changeWebsite(payload));
  });
};

const getInitialFormValues = (website) => {
  const getFeatures = (acc, value) => {
    acc[value] = get(website, value, false);
    return acc;
  };

  return reduce(featureNames, getFeatures, {});
};

export const GeneralFeaturesDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      isConsumerAdmin: isConsumerAdminSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => ({
      formName,
      hasSsoEnabled: get(stateProps.website, 'sso.enabled', false),
      initialValues: getInitialFormValues(stateProps.website),
      onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(GeneralFeatures);
