export const LocalStorageEnum = {
  CT_TOKEN: 'ct-token',
  CT_RATE_VIDEO: 'ct-rate-video',
  CT_IS_PUBLIC: 'ct-is-public',
};

export class CtTokenRepository {
  /**
   * @param {*} ctToken
   */
  static persist(ctToken) {
    localStorage.setItem(LocalStorageEnum.CT_TOKEN, ctToken);
  }

  /**
   * @return {string|null}
   */
  static fetch() {
    return localStorage.getItem(LocalStorageEnum.CT_TOKEN);
  }

  static delete() {
    localStorage.removeItem(LocalStorageEnum.CT_TOKEN);
  }
}
