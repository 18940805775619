/* eslint-disable import/imports-first */
// This file needs to be first in order to apply styles properly
import './bootstrap-corporate-admin.css';
import 'react-tooltip/dist/react-tooltip.css';

import { get } from 'lodash';
import { initConsumerApp } from 'initConsumerApp';
import { initGlobalApp } from 'initGlobalApp';

import './i18n';

const cta = window.__MOVINIMAGE_CORPORATE_TUBE_ADMIN__;
const isConsumerMode = get(cta, 'isConsumerMode');

if (isConsumerMode) {
  initConsumerApp();
} else {
  initGlobalApp();
}
