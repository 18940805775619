import React from 'react';
import { useSelector } from 'react-redux';

import { showCommentFormSelector, showReportVideoFormSelector, isConsumerAdminSelector } from 'selectors';
import { CommentsFeatureDecorated } from './Comments';
import { ReportVideoFeatureDecorated } from './ReportVideo';
import { ShareFeaturesDecorated } from './Share';
import { GeneralFeaturesDecorated } from './General';
import { CustomFeaturesDecorated } from './Custom';

import './index.css';

export function Features() {
  const showCommentForm = useSelector(showCommentFormSelector);
  const showReportVideoForm = useSelector(showReportVideoFormSelector);
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);

  return (
    <>
      {!isConsumerAdmin && (
        <div className="feature__form" data-e2e="GeneralFeatureForm">
          <GeneralFeaturesDecorated />
        </div>
      )}
      {!isConsumerAdmin && (
        <div className="feature__form" data-e2e="ShareOptionsFeatureForm">
          <ShareFeaturesDecorated />
        </div>
      )}
      {showCommentForm && (
        <div className="feature__form">
          <CommentsFeatureDecorated />
        </div>
      )}
      {showReportVideoForm && (
        <div className="feature__form">
          <ReportVideoFeatureDecorated />
        </div>
      )}
      {!isConsumerAdmin && (
        <div className="feature__form">
          <CustomFeaturesDecorated />
        </div>
      )}
    </>
  );
}
