import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Form } from 'components/Form';
import { api } from 'utils/api';
import { renderCheckInput } from 'utils/renderCheckInput';
import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';

import './index.css';

const formName = 'EditVideoListMetadata';
let fields = ['showPlays', 'showRating', 'showUploadDate'];

export function VideoListMetadata(props) {
  const { t } = useTranslation();
  const { website, initialValues } = props;
  const ratingIsEnabled = get(website, 'ratingFeatureEnabled', false);

  if (!ratingIsEnabled) {
    fields = fields.filter((filterName) => filterName !== 'showRating');
  }

  return initialValues ? (
    <Form {...props} formTitle="content.videoListMetadata.header">
      <div className="form-group edit-video-list-metadata-group">
        <label className="title form-label">{t('content.videoListMetadata.label')}</label>
        {fields.map((fieldName) => {
          return (
            <Field
              key={fieldName}
              name={fieldName}
              type="checkbox"
              title={t(`content.videoListMetadata.${fieldName}.label`)}
              component={renderCheckInput}
            />
          );
        })}
        <small className="text-muted">{t('content.videoListMetadata.hint')}</small>
      </div>
    </Form>
  ) : null;
}

const getInitialFormValues = (website) => get(website, 'videoListSettings');

const saveVideoListMetadata = (id) => (dispatch) => (videoListSettings) =>
  api.patch(`/websites/${id}`, { videoListSettings }).then((websitePayload) => {
    dispatch(initialize(formName, getInitialFormValues(websitePayload)));
    dispatch(changeWebsite(websitePayload));
  });

export const VideoListMetadataDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      saveVideoListMetadata,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      initialValues: getInitialFormValues(stateProps.website),
      onSubmit: dispatchProps.saveVideoListMetadata(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(VideoListMetadata);
