import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { reduxForm, initialize, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { get, noop } from 'lodash';

import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { isRequired } from 'utils/formValidators';
import { api } from 'utils/api';
import { websiteSelector, isNotConsumerAdminSelector, cacheSelector } from 'selectors';
import { PLAYERS_LOADED, changeWebsite } from 'actions';

const formName = 'VideoPlayer';

const playersSelector = createSelector(cacheSelector, (cache) => get(cache, 'players'));

const setPlayers = (payload) => ({
  type: PLAYERS_LOADED,
  payload,
});

export function VideoPlayer({ loadPlayerOptions, players, ...props }) {
  const { t } = useTranslation();
  const playerId = get(props.initialValues, 'id');
  const isNotConsumerAdmin = useSelector(isNotConsumerAdminSelector);

  useEffect(() => {
    if (playerId) {
      loadPlayerOptions();
    }
  }, [playerId, loadPlayerOptions]);

  return (
    <Form {...props} formTitle="videoPlayer">
      <Field
        name="id"
        title={t('playerName')}
        description={t('playerIdDescription')}
        component={Select}
        isMandatory
        isSearchable
        validate={isRequired}
        options={players}
        isOptionDisabled={(option) => !!option.newPlayer}
        onInputFocus={loadPlayerOptions}
        formName={formName}
      />
      {isNotConsumerAdmin && (
        <>
          <Field
            name="domain"
            title={t('playerDomain')}
            description={t('playerDomainDescription')}
            component={Input}
            validate={isRequired}
            isMandatory
          />

          <Field
            name="playoutDomain"
            title={t('playoutDomain')}
            description={t('playoutDomainDescription')}
            component={Input}
            validate={isRequired}
            isMandatory
          />
        </>
      )}
    </Form>
  );
}

const changeWebsiteApi = (id) => (dispatch) => (data) => {
  return api.patch(`/websites/${id}`, { player: data }).then((payload) => {
    dispatch(initialize(formName, payload.player));
    dispatch(changeWebsite(payload));
  });
};

const loadPlayerOptions = (websiteId, currentPlayers) => (dispatch) => () =>
  currentPlayers
    ? noop
    : api
        .get(`/websites/${websiteId}/players`)
        .then((players) => dispatch(setPlayers(players)))
        .catch(noop);

const mapStateToProps = (state) => ({
  website: websiteSelector(state),
  players: playersSelector(state),
});

const mapDispatchToProps = {
  changeWebsiteApi,
  loadPlayerOptions,
};

const mergeProps = (stateProps, dispatchProps) => {
  const initialValues = get(stateProps.website, 'player', {});
  const websiteId = get(stateProps.website, 'id');

  return {
    formName,
    initialValues,
    players: stateProps.players,
    loadPlayerOptions: dispatchProps.loadPlayerOptions(websiteId, stateProps.players),
    onSubmit: dispatchProps.changeWebsiteApi(websiteId),
  };
};

export const VideoPlayerDecorated = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(VideoPlayer);
