import React, { useState } from 'react';
import { string, func, shape } from 'prop-types';
import { ChromePicker } from 'react-color';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';

import './index.css';

export function ColorPicker({
  placeholder,
  title,
  isMandatory,
  description,
  disabled,
  inputMaxWidthPx,
  input: { name, onChange, value, onBlur },
  meta: { touched, error },
}) {
  const { t } = useTranslation();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleChange = (color) => onChange && onChange(color);
  const handleInputBlur = (e) => onBlur && onBlur(e.target.value);

  return (
    <Form.Group controlId={name} className="color-picker">
      {title && (
        <Form.Label className="title text-nowrap">
          {title}
          {isMandatory ? <span>&nbsp;*</span> : null}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          name={name}
          data-e2e={name}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleInputBlur}
          isInvalid={touched && error}
          style={{ maxWidth: inputMaxWidthPx - 35 }}
        />
        <InputGroup.Append className="color-picker__append">
          <div
            onClick={disabled ? noop : toggleColorPicker}
            className="color-picker__square"
            style={{ backgroundColor: value }}
          >
            {!value && <div className="line" />}
          </div>
          {displayColorPicker ? (
            <div className="color-picker__popover">
              <div className="color-picker__cover" onClick={disabled ? noop : toggleColorPicker} />
              <ChromePicker disableAlpha color={value} onChangeComplete={({ hex }) => handleChange(hex)} />
            </div>
          ) : null}
        </InputGroup.Append>
        {touched && error && <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>}
      </InputGroup>
      {description && <Form.Text className="text-muted">{description}</Form.Text>}
    </Form.Group>
  );
}

ColorPicker.propTypes = {
  input: shape({
    name: string,
    onChange: func.isRequired,
    onBlur: func,
    value: string,
  }),
  title: string,
  description: string,
  placeholder: string,
};

ColorPicker.defaultProps = {
  meta: { touched: false, error: false },
};
