import React from 'react';
import { Field, initialize, reduxForm, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';

import { FileUpload } from 'components/FileUpload';
import { Input } from 'components/Input';
import { ColorPicker } from 'components/ColorPicker';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { websiteSelector } from 'selectors';
import { Form } from 'components/Form';
import { isPositiveNumber, isPixel, isAuto, isColor } from 'utils/formValidators';
import { initialSassValuesSelector, nextCustomStylesSelector } from '../selectors';
import { LogoSize } from './LogoSize';

const formName = 'DesignHeader';

const isPixelOrAuto = (value) => {
  return (
    (isPositiveNumber(value) || isPixel(value)) && isAuto(value) && i18next.t('form.validation.incorrectValueError')
  );
};

export function Header(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bgImageUrl, bgImageApiUrl, logoImageUrl, logoImageApiUrl } = props;

  return (
    <Form {...props} formTitle="design.header.title">
      <div className="form-group">
        <FileUpload
          fileURL={bgImageUrl}
          apiUrl={bgImageApiUrl}
          title={t('design.header.bgImage.title')}
          description={t('design.header.bgImage.description')}
          acceptedFileTypes=".jpg,.png,.jpeg"
          fileSizeLimit={1000 * 1024}
        />
      </div>

      <div className="design-form__row-inline">
        <Field
          name="$headerHeight"
          title={t('design.header.headerHeight.title')}
          component={Input}
          inputMaxWidthPx={200}
          validate={[isPositiveNumber, isPixel]}
        />

        <Field
          name="$mobileHeaderHeight"
          title={t('design.header.mobileHeaderHeight.title')}
          component={Input}
          inputMaxWidthPx={200}
          validate={[isPositiveNumber, isPixel]}
        />
      </div>

      <Field
        name="$headerBg"
        description={t('design.header.headerBg.description')}
        title={t('design.header.headerBg.title')}
        inputMaxWidthPx={200}
        component={ColorPicker}
        validate={[isColor]}
      />

      <div className="form-group">
        <FileUpload
          fileURL={logoImageUrl}
          apiUrl={logoImageApiUrl}
          title={t('design.header.logoImage.title')}
          description={t('design.header.logoImage.description')}
          acceptedFileTypes=".jpg,.png,.jpeg,.svg"
          fileSizeLimit={200 * 1024}
        />
      </div>

      <div className="design-form__row-inline">
        <Field
          name="$logoWidth"
          description={t('design.header.logoWidth.description')}
          title={t('design.header.logoWidth.title')}
          component={LogoSize}
          validate={[isPixelOrAuto]}
          buttonTitle={t('design.header.logoWidth.buttonTitle')}
          onClick={() => {
            dispatch(change(formName, '$logoWidth', 'auto'));
          }}
        />

        <Field
          name="$logoPositionTop"
          description={t('design.header.logoPositionTop.description')}
          title={t('design.header.logoPositionTop.title')}
          component={Input}
          inputMaxWidthPx={200}
          validate={[isPositiveNumber, isPixel]}
        />
      </div>
    </Form>
  );
}

const getInitialValuesSelector = (state) =>
  initialSassValuesSelector(state, {
    pickBy: ['$logoWidth', '$headerHeight', '$mobileHeaderHeight', '$logoPositionTop', '$headerBg'],
  });

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const customStyles = nextCustomStylesSelector(getState(), { formValues });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, getInitialValuesSelector(getState())));
    });
};

export const HeaderDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      initialValues: getInitialValuesSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: stateProps.initialValues,
        bgImageApiUrl: `/websites/${websiteId}/files/header`,
        bgImageUrl: get(stateProps.website, 'assets.header'),
        logoImageApiUrl: `/websites/${websiteId}/files/logo`,
        logoImageUrl: get(stateProps.website, 'assets.logo'),
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Header);
