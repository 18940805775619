import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown } from 'components/Dropdown';
import { withTranslation } from 'react-i18next';
import { map, get, noop } from 'lodash';
import { websiteSelector } from 'selectors';
import { changeWebsite } from 'actions';
import { api } from 'utils/api';

import './index.css';

const statuses = ['demo', 'creation', 'live', 'deactivated'];

function StatusDropdown({ onSelect, options }) {
  return (
    <div className="statusDropdown" data-e2e="statusDropdown">
      <Dropdown onSelect={onSelect} options={options} alignRight />
    </div>
  );
}

const changeWebsiteApi = (id) => (dispatch) => (data) =>
  api
    .patch(`/websites/${id}`, data)
    .then((payload) => dispatch(changeWebsite(payload)))
    .catch(noop);

export const StatusDropdownDecorated = compose(
  withTranslation(),
  connect(
    (state) => ({
      website: websiteSelector(state),
    }),
    {
      changeWebsiteApi,
    },
    (stateProps, dispatchProps, ownProps) => {
      const options = map(statuses, (status) => ({
        key: status,
        title: ownProps.t(status),
        isActive: get(stateProps.website, 'status') === status,
      }));

      return {
        options,
        onSelect: (status) =>
          dispatchProps.changeWebsiteApi(get(stateProps.website, 'id'))({
            status,
          }),
      };
    }
  )
)(StatusDropdown);
