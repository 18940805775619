import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, filter, toNumber, map, head } from 'lodash';
import { websiteSelector, websitesSelector, vmproSelector, isFeatureMenuItemEnabledSelector } from 'selectors';
import { routes } from 'routes';
import { createSelector } from 'reselect';
import { MenuItems } from 'components/Menu';

const getMenuItems = (isFeaturesItemEnabled) => [
  { id: 'general' },
  { id: 'content' },
  { id: 'security' },
  ...(isFeaturesItemEnabled ? [{ id: 'features' }] : []),
  {
    id: 'design',
    children: [
      { id: 'global' },
      { id: 'typography' },
      { id: 'header' },
      { id: 'navigation' },
      { id: 'contentArea' },
      { id: 'footer' },
      { id: 'miscellaneous' },
    ],
  },
];

const websitesByVmProSelector = createSelector(websitesSelector, vmproSelector, (websites, vmpro) => {
  return filter(websites, ({ account }) => get(account, 'videoManagerId') === toNumber(get(vmpro, 'vmproId')));
});

export function ConsumerMenu() {
  const selectedWebsite = useSelector(websiteSelector);
  const websites = useSelector(websitesByVmProSelector);
  const isFeatureMenuItemEnabled = useSelector(isFeatureMenuItemEnabledSelector);
  const history = useHistory();

  const menuItems = getMenuItems(isFeatureMenuItemEnabled);

  const selectWebsite = (id) => {
    if (get(selectedWebsite, 'id') === id) {
      history.push(routes.websites);
    } else {
      history.push(generatePath(routes[head(menuItems).id], { id }));
    }
  };

  return (
    <div data-e2e="consumerAdminMenu">
      {map(websites, (website) => (
        <React.Fragment key={website.id}>
          <div
            className="menu__title menu__title--consumer"
            data-e2e="adminMenuItem"
            onClick={() => selectWebsite(website.id)}
          >
            {`${website.company} - ${website.name}`}
          </div>
          {selectedWebsite && selectedWebsite.id === website.id && (
            <div className="menu-items">
              <MenuItems items={menuItems} getItemLink={(id) => generatePath(routes[id], { id: selectedWebsite.id })} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
