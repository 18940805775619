import React from 'react';
import { string, func, bool } from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uid } from 'utils/uid';

import './index.css';

export function InputCheck({
  type,
  title,
  customClass,
  onChange,
  description,
  name,
  value,
  isMandatory,
  disabled,
  children,
  meta: { touched, error },
}) {
  const { t } = useTranslation();
  const uniqueId = uid();

  const handleChange = (e) => onChange && onChange(e.target.checked);

  return (
    <Form.Group className={customClass}>
      <div className={`custom-control custom-${type}`}>
        <input
          type="checkbox"
          className="custom-control-input"
          data-e2e={name}
          disabled={disabled}
          name={name}
          checked={value}
          onChange={handleChange}
          id={`${name}-${uniqueId}`}
        />
        <Form.Label htmlFor={`${name}-${uniqueId}`} className="title custom-control-label">
          {title}
          {isMandatory ? <span>&nbsp;*</span> : null}
        </Form.Label>
      </div>
      {touched && error && (
        <Form.Control.Feedback data-e2e={`${name}-error`} type="invalid">
          {t(error)}
        </Form.Control.Feedback>
      )}
      {children}
      {description && <Form.Text className="text-muted">{description}</Form.Text>}
    </Form.Group>
  );
}

InputCheck.defaultProps = {
  meta: { touched: false, error: false },
  type: 'checkbox',
  value: false,
  customClass: '',
};

InputCheck.propTypes = {
  type: string,
  name: string,
  title: string,
  description: string,
  onChange: func.isRequired,
  value: bool,
};
