import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { map } from 'lodash';

import { routes } from 'routes';
import './index.css';

function getExpandedId(items, pathname) {
  const expandedItem = items.find((item) =>
    item.children ? item.children.some(({ id }) => matchPath(pathname, { path: routes[id] })) : false
  );

  return expandedItem ? expandedItem.id : null;
}

function ToggleMenuItem({ id, expanded, onToggle: handleClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={`menu__item-action ${expanded ? 'menu__item-action--expanded' : ''}`}
      onClick={handleClick}
      data-e2e={id}
    >
      {t(`detailPage.menu.${id}`)}
      <span className="menu__item-action__chevron" data-e2e={`${expanded ? 'chevron-down' : 'chevron-right'}`}>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </span>
    </div>
  );
}

function MenuItem({ id, to, active }) {
  const { t } = useTranslation();

  return (
    <Link to={to} data-e2e={id} className={`menu__item-action ${active ? 'menu__item-action--active' : ''}`}>
      {t(`detailPage.menu.${id}`)}
    </Link>
  );
}

export const MenuItems = ({ getItemLink, items }) => {
  const {
    location: { pathname },
  } = useHistory();
  const [expandedId, setExpandedId] = useState(getExpandedId(items, pathname));

  const handleToggleItem = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return map(items, ({ id, children }) =>
    children ? (
      <React.Fragment key={id}>
        <ToggleMenuItem id={id} expanded={expandedId === id} onToggle={() => handleToggleItem(id)} />
        {expandedId === id && (
          <div className="sub-menu-items">
            <MenuItems getItemLink={getItemLink} items={children} />
          </div>
        )}
      </React.Fragment>
    ) : (
      <MenuItem key={id} id={id} to={getItemLink(id)} active={matchPath(pathname, { path: routes[id] })} />
    )
  );
};
