import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, initialize, reduxForm } from 'redux-form';
import { get } from 'lodash';

import { TextArea } from 'components/Input';
import { Form } from 'components/Form';
import { websiteSelector } from 'selectors';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';

const formName = 'EditCSSVariables';

export function CSSVariables(props) {
  return (
    <Form {...props} formTitle="design.cssVariables.header">
      <div className="form-group">
        <Field name="overrides" component={TextArea} rows={12} />
      </div>
    </Form>
  );
}

const getInitialFormValues = (website) => get(website, 'customStyles');

const saveCSSVariables =
  (id) =>
  (dispatch, getState) =>
  ({ overrides }) => {
    const sassVariables = get(websiteSelector(getState()), 'customStyles.sassVariables');
    const customStyles = { overrides, sassVariables };

    return api.patch(`/websites/${id}`, { customStyles }).then((websitePayload) => {
      dispatch(initialize(formName, getInitialFormValues(websitePayload)));
      dispatch(changeWebsite(websitePayload));
    });
  };

export const CSSVariablesDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      saveCSSVariables,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      initialValues: getInitialFormValues(stateProps.website),
      onSubmit: dispatchProps.saveCSSVariables(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(CSSVariables);
