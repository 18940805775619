import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { find } from 'lodash';
import { getFormNames, isDirty } from 'redux-form';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { api } from 'utils/api';
import { routes } from 'routes';
import { APP_CONFIG_LOADED, loadWebsites } from 'actions';
import { isConsumerAdminSelector } from 'selectors';
import { RouteLeavingGuardDecorated } from 'components/RouteLeavingGuard';
import { NotificationsContainerDecorated } from 'components/Notifications';

import { CorporateTubePage } from 'pages/CorporateTube';
import { OverviewPage } from 'pages/OverviewPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { Header } from './Header';
import './index.css';

const isAnyFormDirtySelector = (state) => {
  const names = getFormNames()(state);
  return !!find(names, (name) => isDirty(name)(state));
};

const loadWebsitesApi = () => (dispatch) => {
  return api.get('/websites').then((payload) => dispatch(loadWebsites(payload)));
};

const appConfigLoaded = (payload) => ({
  type: APP_CONFIG_LOADED,
  payload,
});

const fetchAppConfig = (dispatch) => {
  api.get('/app/config').then((appConfig) => dispatch(appConfigLoaded(appConfig)));
};

function GlobalRoutes() {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/ct/:id" component={CorporateTubePage} />
        <Route exact path="/" component={OverviewPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}

function ConsumerRoutes() {
  return (
    <Switch>
      <Route path="/ct" component={CorporateTubePage} />
      <Redirect from="/" to={routes.websites} />
    </Switch>
  );
}

function Routes() {
  const isAnyFormDirty = useSelector(isAnyFormDirtySelector);
  const dispatch = useDispatch();
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);

  useEffect(() => {
    fetchAppConfig(dispatch);
    dispatch(loadWebsitesApi());
  }, [dispatch]);

  return (
    <>
      <div className="cta-pages">{isConsumerAdmin ? <ConsumerRoutes /> : <GlobalRoutes />}</div>
      <RouteLeavingGuardDecorated when={isAnyFormDirty} />
      <NotificationsContainerDecorated />
    </>
  );
}

export function App({ store, history }) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="cta-root" data-e2e="cta-root">
          <Routes />
          <div id="modal-root" />
        </div>
      </ConnectedRouter>
    </Provider>
  );
}
