/* eslint-disable no-console */
export const config = {
  keycloak: {
    url: process.env.REACT_APP_ADMIN_AUTH_URL,
    realm: process.env.REACT_APP_ADMIN_AUTH_REALM,
    clientId: process.env.REACT_APP_ADMIN_AUTH_RESOURCE,
    idpHint: process.env.REACT_APP_ADMIN_AUTH_IDP_HINT,
  },
  ctTokenCheckInterval: 30,
};

if (!process.env.REACT_APP_ADMIN_AUTH_URL) {
  console.error(`no $REACT_APP_ADMIN_AUTH_URL provided`);
}

if (!process.env.REACT_APP_ADMIN_AUTH_REALM) {
  console.error(`no $REACT_APP_ADMIN_AUTH_REALM provided`);
}

if (!process.env.REACT_APP_ADMIN_AUTH_RESOURCE) {
  console.error(`no $REACT_APP_ADMIN_AUTH_RESOURCE provided`);
}

if (!process.env.REACT_APP_ADMIN_AUTH_IDP_HINT) {
  console.error(`no $REACT_APP_ADMIN_AUTH_IDP_HINT provided`);
}
