import { size } from 'lodash';

export const isRequired = (value) => {
  if (!value) {
    return 'requiredField';
  }

  if (Array.isArray(value)) {
    return value.length ? undefined : 'requiredField';
  }
  return undefined;
};

export const isNumber = (value) => (value && Number.isNaN(Number(value)) ? 'numericField' : undefined);

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const isValidEmail = (email) => emailRegex.test(email);

const domainRegex = /^(?:\*\.)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,6}$/i;
const isValidDomain = (domain) => domainRegex.test(domain);

export const splitStrings = (values) => (values ? values.split(',').map((value) => value.trim()) : []);
export const joinToString = (list = []) => list.join(', ');

export const isCommaSeparatedEmailList = (value) => {
  if (!value) {
    return undefined;
  }

  const emailsArray = splitStrings(value);
  return emailsArray.every(isValidEmail) ? undefined : 'form.validation.emailListFieldError';
};

export const isCommaSeparatedDomainList = (value) => {
  if (!value) {
    return undefined;
  }

  const domains = splitStrings(value);
  return domains.every(isValidDomain) ? undefined : 'form.validation.domainListFieldError';
};

export const isColor = (value) => {
  if (!value) {
    return undefined;
  }
  return /^(#([a-f\d]{3}|[a-f\d]{6})|rgb\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\)|rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0?\.\d|1(\.0)?)\)|hsl\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%\)|hsla\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%,(0?\.\d|1(\.0)?)\))$/i.test(
    value
  )
    ? undefined
    : 'form.validation.colorFieldError';
};

export const isPositiveNumber = (value) => {
  if (!value) {
    return undefined;
  }
  return /^[+]?([.]\d+|\d+([.]\d+)?)/.test(value) ? undefined : 'form.validation.positiveFieldError';
};

const isValidUnit = (unit, errorKey) => (value) => {
  if (!value || value === '0') {
    return undefined;
  }

  const regex = new RegExp(`^[+-]?(\\d*.?\\d+)(${unit})$`);
  return regex.test(value) ? undefined : errorKey;
};

export const isPixel = isValidUnit('px', 'form.validation.pixelFieldError');

export const isEm = isValidUnit('em', 'form.validation.emFieldError');

export const isAuto = (value) => {
  if (!value) {
    return undefined;
  }

  return value === 'auto' ? undefined : 'form.validation.autoFieldError';
};

export const isAtLeastOneChecked = (languages) => (size(languages) ? undefined : 'form.validation.languagesListError');
