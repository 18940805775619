import React from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

export function CustomOption({ innerProps, isDisabled, isFocused, value, isSelected, label }) {
  return (
    <div
      {...innerProps}
      className={classNames('ct-option', { focused: isFocused }, { selected: isSelected }, { disabled: isDisabled })}
      data-e2e="ct-option"
      key={value.id}
    >
      <span
        style={
          value.level && {
            marginLeft: value.level * 10,
          }
        }
      >
        {label}
      </span>

      {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
}
