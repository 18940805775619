import { get, orderBy, filter, includes, isEmpty, toLower, every } from 'lodash';
import {
  pipe as fpPipe,
  trim as fpTrim,
  split as fpSplit,
  filter as fpFilter,
  compact as fpCompact,
  map as fpMap,
} from 'lodash/fp';
import { createSelector } from 'reselect';

import {
  selectedStatusesSelector,
  websitesSelector,
  filterCorporateTubesBySelector,
  sortCorporateTubesBySelector,
  selectedAccessLevelsSelector,
} from 'selectors';
import { WebsiteAccessLevel } from 'api/websiteAccessLevel';

const addCorporateTubeTitle = (tube) => ({
  ...tube,
  title: tube.internalName || tube.name,
});

export const filteredCorporateTubesSelector = (state) => {
  const tubes = websitesSelector(state).map(addCorporateTubeTitle);
  const selectedStatuses = selectedStatusesSelector(state);
  const selectedAccessLevels = selectedAccessLevelsSelector(state);
  const keywordsExcluded = filterCTByExcludedKeywordsSelector(state);
  const keywords = filterCTByKeywordsSelector(state);

  const isFilteredByStatuses = (tube) => isEmpty(selectedStatuses) || includes(selectedStatuses, tube.status);

  const isFilteredByAccessLevels = (tube) => {
    return isEmpty(selectedAccessLevels) || includes(selectedAccessLevels, getWebsiteAccessLevel(tube));
  };

  const isFilteredByExcludedKeywords = (website, keywords) =>
    isEmpty(keywords) || !isFilteredByKeywords(website, keywords);

  const isFilteredByKeywords = (website, keywords) =>
    isEmpty(keywords) || every(keywords, (keyword) => isIncluded(website, keyword));

  const isIncluded = (website, keyword) => {
    const { company, title } = website;

    return includes(toLower(company), toLower(keyword)) || includes(toLower(title), toLower(keyword));
  };

  return filter(
    tubes,
    (tube) =>
      isFilteredByStatuses(tube) &&
      isFilteredByAccessLevels(tube) &&
      isFilteredByExcludedKeywords(tube, keywordsExcluded) &&
      isFilteredByKeywords(tube, keywords)
  );
};

export const getWebsiteAccessLevel = ({ sso }) => {
  return get(sso, 'enabled', false) ? WebsiteAccessLevel.PROTECTED : WebsiteAccessLevel.PUBLIC;
};

export const sortedAndfilteredCorporateTubesSelector = (state) => {
  const filteredCorporateTubes = filteredCorporateTubesSelector(state);
  const { field, sorting } = sortCorporateTubesBySelector(state);

  return sorting
    ? orderBy(filteredCorporateTubes, (tube) => toLower(get(tube, field)), sorting)
    : filteredCorporateTubes;
};

export const filterCTByKeywordsSelector = createSelector(
  filterCorporateTubesBySelector,
  fpPipe(
    fpTrim,
    fpSplit(' '),
    fpFilter((word) => !word.startsWith('!')),
    fpCompact
  )
);

const filterCTByExcludedKeywordsSelector = createSelector(
  filterCorporateTubesBySelector,
  fpPipe(
    fpTrim,
    fpSplit(' '),
    fpFilter((word) => word.startsWith('!')),
    fpMap((word) => word.slice(1)),
    fpCompact
  )
);
