import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field, getFormValues } from 'redux-form';
import { Input } from 'components/Input';
import { renderCheckInput } from 'utils/renderCheckInput';
import { isRequired } from 'utils/formValidators';
import { get } from 'lodash';
import { websiteSelector, appConfigSelector } from 'selectors';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';

const formName = 'SSO';

export function SSO(props) {
  const { t } = useTranslation();
  const { formValues } = props;

  const isSSOEnabled = get(formValues, 'enabled', false);

  const toggleTitle = isSSOEnabled ? t('security.sso-toggle.enabled') : t('security.sso-toggle.disabled');

  return (
    <Form {...props} formTitle="security.sso">
      <Field name="enabled" type="switch" title={toggleTitle} component={renderCheckInput} />

      <Field
        name="idpHint"
        title={t('security.idpHint.label')}
        description={t('security.idpHint.hint')}
        component={Input}
        isMandatory={isSSOEnabled}
        validate={isSSOEnabled ? isRequired : null}
      />

      <Field
        name="authRealm"
        title={t('security.authRealm.label')}
        description={t('security.authRealm.hint')}
        component={Input}
        isMandatory={isSSOEnabled}
        validate={isSSOEnabled ? isRequired : null}
      />

      <Field
        name="authResource"
        title={t('security.authResource.label')}
        description={t('security.authResource.hint')}
        component={Input}
        isMandatory={isSSOEnabled}
        validate={isSSOEnabled ? isRequired : null}
      />

      <Field
        name="authUrl"
        title={t('security.authenticationUrl.label')}
        description={t('security.authenticationUrl.hint')}
        component={Input}
        isMandatory={isSSOEnabled}
        validate={isSSOEnabled ? isRequired : null}
      />
    </Form>
  );
}

const updateWebsite = (id) => (dispatch) => (sso) => {
  const payload = { sso };

  // turn off referrer protection,
  // because the redirect in CT-Web to the keycloak login page and back
  // removes the referrer and break the referrer logic
  if (sso.enabled) {
    payload.referrerProtection = { enabled: false };
  }

  return api.patch(`/websites/${id}`, payload).then((website) => {
    dispatch(
      initialize(formName, {
        ...website.sso,
      })
    );
    dispatch(changeWebsite(website));
  });
};

const ssoDefaultsSelector = (state) => {
  const settings = appConfigSelector(state);
  return get(settings, 'defValues.website.sso');
};

export const SSODecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      ssoDefaults: ssoDefaultsSelector(state),
      formValues: getFormValues(formName)(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const { ssoDefaults, formValues } = stateProps;

      const initialValues = get(stateProps.website, 'sso', {
        ...ssoDefaults,
        enabled: false,
      });

      return {
        formName,
        formValues,
        initialValues,
        onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(SSO);
