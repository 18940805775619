import React from 'react';
import { useSelector } from 'react-redux';
import { map, get } from 'lodash';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { cacheSelector } from 'selectors';
import { CustomFont } from './CustomFont';
import { AddCustomFont } from './AddCustomFont';

import './index.css';

export function CustomFontUpload(props) {
  const { t } = useTranslation();
  const { handleSubmit } = props;
  const fonts = get(useSelector(cacheSelector), 'fonts');
  const formTitle = 'design.customFontUpload.title';

  return (
    <form onSubmit={handleSubmit} data-e2e={`${formTitle}Form`} className="cta-form">
      <div className="form__title">{t(formTitle)}</div>
      <div className="form__body">
        {fonts ? (
          map(fonts, (font) => (
            <div key={font.id} className="custom-font-upload__custom_font">
              <CustomFont font={font} />
            </div>
          ))
        ) : (
          <div className="custom-font-upload__spinner">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="button__spinner"
              data-e2e="cta-spinner"
            />
          </div>
        )}
        <AddCustomFont />
      </div>
    </form>
  );
}
