import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, size } from 'lodash';
import { ConfirmationModal } from 'components/Modal';

import { Button } from 'components/Button';
import { api } from 'utils/api';
import { websiteIdSelector, cacheSelector } from 'selectors';
import { FONT_ADD } from 'actions';

import { useTranslation } from 'react-i18next';

import './AddCustomFont.css';

const uploadFont = (websiteId, files) => api.post(`/websites/${websiteId}/fonts`, { files }, false);

export function AddCustomFont() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const websiteId = useSelector(websiteIdSelector);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isConfirmationShown, showConfirmation] = useState(false);
  const fonts = get(useSelector(cacheSelector), 'fonts');
  const disabled = !fonts || size(fonts) >= 5;
  const fileEl = useRef();

  const onFontUploadClick = () => {
    showConfirmation(true);
  };

  const onFontChoosen = (e) => {
    // FileList object
    const fileList = get(e.target, 'files');

    if (fileList && fileList.length > 0) {
      handleUpload(websiteId, fileList);
    }
  };

  const handleUpload = (websiteId, file) => {
    setLoading(true);

    uploadFont(websiteId, file)
      .then((payload) => {
        dispatch({ type: FONT_ADD, payload });
        setError(null);
      })
      .catch(({ errors }) => {
        setError(errors.filename);
      })
      .finally(() => {
        resetUploadInput();
        setLoading(false);
      });
  };

  const resetUploadInput = () => {
    if (fileEl.current) {
      fileEl.current.value = null;
    }
  };

  const handleConfirm = () => {
    showConfirmation(false);
    fileEl.current.click();
  };

  const handleCancel = () => {
    showConfirmation(false);
  };

  return (
    <>
      {isConfirmationShown && (
        <ConfirmationModal
          primaryButtonLabel="confirm"
          handleOk={handleConfirm}
          isVisible
          handleCancel={handleCancel}
          header={t('design.customFontUpload.uploadButton.label')}
        >
          {t('design.customFontUpload.confirmationMessage')}
        </ConfirmationModal>
      )}

      <small className="text-muted form-text font-uploader__description">
        {t('design.customFontUpload.uploadButton.description')}
        <br />
        {t('design.customFontUpload.uploadButton.formats')}
      </small>

      <Button
        variant="secondary"
        className="font-uploader__button"
        data-e2e="fontUploadButton"
        isLoading={isLoading}
        onClick={onFontUploadClick}
        disabled={disabled}
      >
        {!isLoading && t('design.customFontUpload.uploadButton.label')}
      </Button>
      <input
        ref={fileEl}
        data-e2e="fontUploader"
        type="file"
        multiple
        className="font-uploader__input"
        onChange={onFontChoosen}
        accept=".ot,.otf,.eot,.rtt,.svg,.ttf,.woff,.woff2"
        title={t('fileUploader.title')}
      />
      {error && (
        <small data-e2e="upload-font-error" className="text-danger form-text">
          {t(error)}
        </small>
      )}
    </>
  );
}
