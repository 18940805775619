import { LOAD_WEBSITES, DELETE_WEBSITE, CHANGE_WEBSITE, ADD_WEBSITE } from 'actions';
import { filter, map } from 'lodash';

export const websitesReducer = (state = [], action) => {
  switch (action.type) {
    case LOAD_WEBSITES:
      return action.payload;

    case DELETE_WEBSITE:
      return filter(state, (website) => website.id !== action.payload.websiteId);

    case CHANGE_WEBSITE:
      return map(state, (website) => (website.id === action.payload.id ? action.payload : website));

    case ADD_WEBSITE:
      return [...state, action.payload];

    default:
      return state;
  }
};
