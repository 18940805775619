import React from 'react';
import { useSelector } from 'react-redux';
import { isConsumerAdminSelector } from 'selectors';
import { OtherComponentsDecorated } from './OtherComponents';
import { CSSVariablesDecorated } from './CssVariables';

export function Miscellaneous() {
  const isConsumerAdmin = useSelector(isConsumerAdminSelector);

  return (
    <>
      <div className="design-form">
        <OtherComponentsDecorated />
      </div>
      {!isConsumerAdmin && (
        <div className="design-form">
          <CSSVariablesDecorated />
        </div>
      )}
    </>
  );
}
