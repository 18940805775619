import React from 'react';
import { string, func, shape } from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormGroup } from 'components/FormGroup';
import { Button } from 'components/Button';

import './index.css';

export function LogoSize({
  title,
  buttonTitle,
  input: { name, onChange, onBlur, value },
  description,
  placeholder,
  isMandatory,
  disabled,
  meta,
  onClick,
}) {
  const { touched, error } = meta;
  const handleChange = (e) => onChange && onChange(e.target.value);
  const handleBlur = (e) => onBlur && onBlur(e.target.value);
  const { t } = useTranslation();

  return (
    <FormGroup title={title} description={description} isMandatory={isMandatory} meta={meta} name={name}>
      <div className="logo-size__children">
        <Form.Control
          type="text"
          size="sm"
          name={name}
          data-e2e={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          isInvalid={touched && error}
        />
        <Button variant="secondary" onClick={onClick} data-e2e={`${name}-button`}>
          {buttonTitle}
        </Button>
      </div>
      {touched && error && <Form.Text className="text-danger">{t(error)}</Form.Text>}
    </FormGroup>
  );
}

LogoSize.propTypes = {
  input: shape({
    name: string,
    onChange: func.isRequired,
    onBlur: func,
    value: string,
  }),
};
