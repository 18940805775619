import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, initialize, Field } from 'redux-form';
import { renderCheckInput } from 'utils/renderCheckInput';
import { get } from 'lodash';
import { websiteSelector } from 'selectors';
import { Form } from 'components/Form';
import { api } from 'utils/api';
import { changeWebsite } from 'actions';

const formName = 'CustomFeatures';
const customFeature = 'uitVideoDownloadFeatureEnabled';

function CustomFeatures(props) {
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="features.custom.title">
      <Field
        key={customFeature}
        name={customFeature}
        type="checkbox"
        title={t(`features.custom.${customFeature}`)}
        component={renderCheckInput}
      />
    </Form>
  );
}

const updateWebsite = (id) => (dispatch) => (formData) => {
  return api.patch(`/websites/${id}`, formData).then((payload) => {
    dispatch(initialize(formName, payload));
    dispatch(changeWebsite(payload));
  });
};

export const CustomFeaturesDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => ({
      formName,
      initialValues: {
        [customFeature]: get(stateProps.website, customFeature, false),
      },
      onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(CustomFeatures);
