import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { initI18nConsumer } from 'i18n';

import { App } from 'pages/App';
import { configureStore } from 'store/configureStore';
import { CtTokenRepository } from 'utils/ct-token.repository';

import { initToggles } from './feature.toggles';
import './vmpro.css';

function ConsumerAdmin({ store, history, ctToken }) {
  CtTokenRepository.persist(ctToken);

  return <App store={store} history={history} />;
}

const mountConsumerAdmin = ({ rootEl, basename, ctToken, vmproId, userLanguage }) => {
  const initialState = {
    vmpro: {
      vmproId,
      userLanguage,
    },
    isConsumerApp: true,
  };

  const $rootEl = document.querySelector(rootEl);
  const root = createRoot($rootEl);

  if (!$rootEl) {
    console.error('rootEl element is not in the DOM', rootEl); // eslint-disable-line no-console
    return;
  }

  const history = createBrowserHistory({
    basename,
  });

  const store = configureStore(history, initialState);
  initToggles(store.dispatch);
  initI18nConsumer(userLanguage);

  root.render(<ConsumerAdmin store={store} history={history} ctToken={ctToken} />);

  // returns unmount function
  // eslint-disable-next-line consistent-return
  return () => {
    root.unmount();
  };
};

const logout = () => {
  CtTokenRepository.delete();
};

export const initConsumerApp = () => {
  const cta = window.__MOVINIMAGE_CORPORATE_TUBE_ADMIN__;
  cta.mount = mountConsumerAdmin;
  cta.logout = logout;
  if (cta.onLoad) {
    cta.onLoad();
  }
};
