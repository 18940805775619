import React from 'react';
import { useSelector } from 'react-redux';

import { isNotConsumerAdminSelector } from 'selectors';
import { GeneralInfoDecorated } from 'pages/GeneralSettings/GeneralInfo';
import { HostnameDecorated } from 'pages/GeneralSettings/Hostname';
import { AccountDecorated } from 'pages/GeneralSettings/Account';
import { AccountConfirmationDialog } from 'pages/GeneralSettings/Account/AccountConfirmationDialog';
import { AssetsDecorated } from 'pages/GeneralSettings/Assets';
import { VideoPlayerDecorated } from 'pages/GeneralSettings/VideoPlayer';

import './index.css';

export function GeneralSettings() {
  const isNotConsumerAdmin = useSelector(isNotConsumerAdminSelector);

  return (
    <div data-e2e="general-settings">
      <div className="general-settings__form">
        <GeneralInfoDecorated />
      </div>

      {isNotConsumerAdmin && (
        <div className="general-settings__form">
          <HostnameDecorated />
        </div>
      )}

      {isNotConsumerAdmin && (
        <div className="general-settings__form">
          <AccountConfirmationDialog />
          <AccountDecorated />
        </div>
      )}

      <div className="general-settings__form">
        <VideoPlayerDecorated />
      </div>

      {isNotConsumerAdmin && (
        <div className="general-settings__form">
          <AssetsDecorated />
        </div>
      )}
    </div>
  );
}
