import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import { get } from 'lodash';
import { createSelector } from 'reselect';

import { Form } from 'components/Form';
import { TextArea } from 'components/Input';
import { changeWebsite } from 'actions';
import { websiteSelector } from 'selectors';
import { api } from 'utils/api';
import { renderCheckInput } from 'utils/renderCheckInput';
import { isCommaSeparatedDomainList, isRequired, joinToString, splitStrings } from 'utils/formValidators';

const hasSSOEnabledSelector = createSelector(websiteSelector, (website) => {
  return get(website, 'sso.enabled', false);
});

const formName = 'ReferrerProtection';

export function ReferrerProtection(props) {
  const { t } = useTranslation();
  const { formValues } = props;
  const isEnabled = get(formValues, 'enabled', false);
  const ssoEnabled = useSelector(hasSSOEnabledSelector);

  const domainsFieldValidator = isEnabled ? [isRequired, isCommaSeparatedDomainList] : isCommaSeparatedDomainList;

  const toggleTitle = isEnabled
    ? t('security.referrer-protection.toggle.enabled')
    : t('security.referrer-protection.toggle.disabled');

  return (
    <Form {...props} formTitle="security.referrer-protection.header" disabled={ssoEnabled}>
      <Field name="enabled" type="switch" disabled={ssoEnabled} title={toggleTitle} component={renderCheckInput} />

      <Field
        name="referrers"
        title={t('security.referrer-protection.referrers.title')}
        description={t('security.referrer-protection.referrers.description')}
        disabled={ssoEnabled}
        component={TextArea}
        isMandatory={isEnabled}
        validate={domainsFieldValidator}
      />
    </Form>
  );
}

const updateWebsite =
  (id) =>
  (dispatch) =>
  async ({ enabled, referrers }) => {
    const referrerProtection = {
      enabled,
      referrers: splitStrings(referrers),
    };

    const payload = await api.patch(`/websites/${id}`, { referrerProtection });
    const initialValues = getInitialValues(payload);

    dispatch(initialize(formName, initialValues));
    dispatch(changeWebsite(payload));
  };

const getInitialValues = (website) => {
  const referrerProtection = get(website, 'referrerProtection', {});
  const enabled = get(referrerProtection, 'enabled', false);

  return {
    enabled,
    referrers: joinToString(referrerProtection.referrers),
  };
};

export const ReferrerProtectionDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      formValues: getFormValues(formName)(state),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const { formValues } = stateProps;
      const initialValues = getInitialValues(stateProps.website);

      return {
        formName,
        formValues,
        initialValues,
        onSubmit: dispatchProps.updateWebsite(get(stateProps.website, 'id')),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(ReferrerProtection);
