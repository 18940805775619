import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { withTranslation, useTranslation } from 'react-i18next';
import { websiteSelector } from 'selectors';
import { toggleDeleteCTModal } from 'actions';
import { Button } from 'components/Button';
import { DeleteConfirmationModalDecorated } from './DeleteConfirmationModal';

function DeleteButton({ toggleDeleteCTModal, isVisible }) {
  const { t } = useTranslation();

  return (
    isVisible && (
      <div>
        <Button variant="danger" onClick={toggleDeleteCTModal} type="button" data-e2e="deleteCT">
          {t('deleteCorporateTubeButton')}
        </Button>

        <DeleteConfirmationModalDecorated />
      </div>
    )
  );
}

export const DeleteButtonDecorated = compose(
  withTranslation(),
  connect(
    (state) => ({
      website: websiteSelector(state),
    }),
    {
      toggleDeleteCTModal,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...dispatchProps,
      ...ownProps,
      isVisible: get(stateProps.website, 'status') === 'deactivated',
    })
  )
)(DeleteButton);
