import React from 'react';
import { InputCheck } from 'components/Input';

export const renderCheckInput = ({
  input: { name, onChange, value },
  type,
  meta,
  customClass,
  placeholder,
  title,
  description,
  isMandatory,
  children,
  disabled,
}) => (
  <InputCheck
    type={type}
    name={name}
    customClass={customClass}
    onChange={onChange}
    placeholder={placeholder}
    title={title}
    description={description}
    value={Boolean(value)}
    isMandatory={isMandatory}
    disabled={disabled}
    meta={meta}
  >
    {children}
  </InputCheck>
);
