import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';

import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { WebsiteAccessLevel } from 'api/websiteAccessLevel';
import { HightlightedContent } from './HightlightedContent/index';
import { sortedAndfilteredCorporateTubesSelector, getWebsiteAccessLevel } from '../selectors';

import './index.css';

const getHeader = (t) => [
  { content: t('company'), sortable: true },
  { content: t('title'), sortable: true },
  { content: t('status') },
  { content: t('access') },
  { content: t('hostname') },
];

function StatusContent({ status }) {
  const { t } = useTranslation();

  return <Badge variant={`${status} sm`}>{t(status)}</Badge>;
}

function AccessContent({ accessLevel }) {
  const { t } = useTranslation();

  return accessLevel === WebsiteAccessLevel.PROTECTED ? (
    <span className="corporate-tube-table__access-col">
      {t(accessLevel)}
      &nbsp;
      <FontAwesomeIcon icon={faLock} size="xs" />
    </span>
  ) : (
    <span className="corporate-tube-table__access-col">{t(accessLevel)}</span>
  );
}

function ExternalLinkContent({ hostname }) {
  return hostname ? (
    <a
      className="corporate-tube-table__link"
      target="_blank"
      data-e2e="external-link"
      rel="noopener noreferrer"
      href={`https://${hostname}`}
      onClick={(e) => e.stopPropagation()}
    >
      {hostname}
    </a>
  ) : null;
}

const tubes2rows = (tubes) => {
  return map(tubes, (tube) => {
    const { id, company, title, status, hostname } = tube;
    const accessLevel = getWebsiteAccessLevel(tube);

    return {
      id,
      cols: [
        {
          content: <HightlightedContent text={company} />,
        },
        {
          content: <HightlightedContent text={title} />,
        },
        { content: <StatusContent status={status} /> },
        { content: <AccessContent accessLevel={accessLevel} /> },
        {
          content: <ExternalLinkContent hostname={hostname} />,
          truncate: true,
        },
      ],
    };
  });
};

const goToCorporateTube = (history) => () => (id) => {
  history.push(`/ct/${id}/general`);
};

export function CorporateTubeTable({ handleClick, sortedAndfilteredCorporateTubes, t }) {
  return sortedAndfilteredCorporateTubes ? (
    <div data-e2e="tubes">
      <Table
        header={getHeader(t)}
        rows={tubes2rows(sortedAndfilteredCorporateTubes)}
        emptyMessage={t('emptyWebsiteListMessage')}
        handleClick={handleClick}
      />
    </div>
  ) : null;
}

export const CorporateTubeTableDecorated = compose(
  withRouter,
  withTranslation(),
  connect(
    (state) => ({
      sortedAndfilteredCorporateTubes: sortedAndfilteredCorporateTubesSelector(state),
    }),
    {
      goToCorporateTube,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      handleClick: dispatchProps.goToCorporateTube(ownProps.history),
    })
  )
)(CorporateTubeTable);
