import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { size } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'components/Button';
import { toggleCreateCTModal } from 'actions';
import { websitesSelector } from 'selectors';

import { KeywordFilter } from './KeywordFilter';
import { StatusFilter } from './StatusFilter';
import { AccessFilter } from './AccessFilter';
import { CreateCorporateTubeDecorated } from './CreateCorporateTube';
import { CorporateTubeTableDecorated } from './CorporateTubeTable';
import { filteredCorporateTubesSelector } from './selectors';

import './index.css';

function filteredCorporateTubesCountSelector(state) {
  const filteredTubesNumber = size(filteredCorporateTubesSelector(state));
  const tubesNumber = size(websitesSelector(state));
  return tubesNumber > 0 && filteredTubesNumber !== tubesNumber ? filteredTubesNumber : -1;
}

function TopToolBar({ onCreateBtnClicked }) {
  const { t } = useTranslation();
  const filteredCorporateTubesCount = useSelector(filteredCorporateTubesCountSelector);

  return (
    <div className="overview-page__toolbar">
      <span className="overview-page__title">
        {t('corporateTubes')}
        {filteredCorporateTubesCount >= 0 && (
          <span className="overview-page__tubes-number" data-e2e="tubesNumber">
            {`(${t('filteredCorporateTubes.count', { count: filteredCorporateTubesCount })})`}
          </span>
        )}
      </span>
      <Button variant="primary" onClick={onCreateBtnClicked} data-e2e="createCT">
        <span className="overview-page__create-ct-button-title">
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;
          {t('createct')}
        </span>
      </Button>
    </div>
  );
}

function FilterBar() {
  const filteredCorporateTubes = useSelector(filteredCorporateTubesSelector);

  return filteredCorporateTubes ? (
    <div className="overview-page__filter-bar">
      <KeywordFilter />
      <StatusFilter />
      <AccessFilter />
    </div>
  ) : null;
}

export function OverviewPage() {
  const dispatch = useDispatch();

  return (
    <div className="overview-page__page">
      <TopToolBar onCreateBtnClicked={() => dispatch(toggleCreateCTModal())} />
      <FilterBar />
      <CorporateTubeTableDecorated />
      <CreateCorporateTubeDecorated />
    </div>
  );
}
