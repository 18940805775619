import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, isValid, initialize } from 'redux-form';
import { Buttons } from 'components/Form/Buttons';
import { withTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';
import { uiSelector } from 'selectors';
import { toggleCreateCTModal, addWebsite } from 'actions';
import { GlobalGeneralInfo } from 'pages/GeneralSettings/GeneralInfo';
import { api } from 'utils/api';

const formName = 'CreateCorporateTube';

export function CreateCorporateTube({ pristine, reset, submitting, handleSubmit, show, t, onHide, error }) {
  const cancel = () => {
    onHide();
    reset();
  };
  const valid = useSelector(isValid(formName));

  return (
    <Modal isVisible={show} onHide={cancel} header={t('newCorporateTube')}>
      <form className="create-ct__form" onSubmit={handleSubmit}>
        <GlobalGeneralInfo formName={formName} />
        {error && <small className="text-danger">{error}</small>}
        <Buttons
          pristine={pristine}
          reset={cancel}
          submitting={submitting}
          primaryTitle={t('create')}
          submittingTitle={t('creating')}
          secondaryTitle={t('cancel')}
          secondaryDisabled={submitting}
          valid={valid}
        />
      </form>
    </Modal>
  );
}

const createCorporateTube = (history) => (dispatch) => (data) => {
  return api.post('/websites', data).then((payload) => {
    dispatch(toggleCreateCTModal());
    dispatch(addWebsite(payload));
    dispatch(initialize(formName, payload));
    const { id } = payload;
    // By making redirection inside a timeout, we give the form some time to reinitialize.
    // This prevents the form from staying dirty and showing the RouteLeavingGuard modal.
    setTimeout(() => history.push(`/ct/${id}/general`));
  });
};

export const CreateCorporateTubeDecorated = compose(
  withTranslation(),
  withRouter,
  connect(
    (state) => ({ show: uiSelector(state).isCreateCTModalShown }),
    {
      onHide: toggleCreateCTModal,
      createCorporateTube,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      initialValues: {
        languages: ['en', 'de'],
      },
      onSubmit: dispatchProps.createCorporateTube(ownProps.history),
    })
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(CreateCorporateTube);
