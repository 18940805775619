import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

import { hideNotification } from 'actions';
import { notificationsSelector } from 'selectors';
import './index.css';

export const NOTIFICATION_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

function Notification({ notification, hideNotification }) {
  const { id, type, message, timeout } = notification;

  useEffect(() => {
    return () => clearTimeout(timeoutRef);
  });

  const timeoutRef =
    timeout &&
    setTimeout(() => {
      hideNotification({ id });
    }, timeout);

  const onClose = () => {
    hideNotification({ id: notification.id });
  };

  return (
    <div className={`notification__base notification__${type}`}>
      <div className="notification__icon">
        <span>
          {type === NOTIFICATION_TYPES.ERROR && (
            <FontAwesomeIcon className="notification__error-icon" size="lg" icon={faTimesCircle} />
          )}
          {type === NOTIFICATION_TYPES.SUCCESS && (
            <FontAwesomeIcon className="notification__success-icon" size="lg" icon={faCheckCircle} />
          )}
        </span>
      </div>

      <div className="notification__body">{message}</div>

      <div className="notification__close">
        <span className="notification__close-icon" onClick={onClose}>
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </span>
      </div>
    </div>
  );
}

function NotificationsContainer({ notifications, hideNotification }) {
  return (
    <div data-e2e="toast-notification" className="notification__containers">
      {notifications.map((notification) => (
        <div key={notification.id} className="notification__container">
          <Notification notification={notification} hideNotification={hideNotification} />
        </div>
      ))}
    </div>
  );
}

export const NotificationsContainerDecorated = compose(
  connect(
    (state) => ({
      notifications: notificationsSelector(state),
    }),
    {
      hideNotification,
    },
    (stateProps, dispatchProps, ownProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
      };
    }
  )
)(NotificationsContainer);
