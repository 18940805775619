import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { api } from 'utils/api';
import { changeWebsite } from 'actions';
import { websiteSelector } from 'selectors';
import { FileUpload } from 'components/FileUpload';
import { Input } from 'components/Input';
import { Form } from 'components/Form';
import { isPositiveNumber, isPixel } from 'utils/formValidators';
import { initialSassValuesSelector, nextCustomStylesSelector } from '../selectors';

const formName = 'DesignGlobal';

export function Global(props) {
  const { faviconUrl, faviconApiUrl } = props;
  const { t } = useTranslation();

  return (
    <Form {...props} formTitle="design.global.title">
      <div className="form-group">
        <FileUpload
          fileURL={faviconUrl}
          apiUrl={faviconApiUrl}
          title={t('design.global.uploadTitle')}
          description={t('design.global.uploadDescription')}
          acceptedFileTypes=".ico,.png"
          fileSizeLimit={1000 * 1024}
        />
      </div>

      <Field
        name="$borderRadius"
        description={t('design.global.roundedCornersDescription')}
        title={t('design.global.roundedCornersTitle')}
        component={Input}
        inputMaxWidthPx={200}
        validate={[isPositiveNumber, isPixel]}
      />
    </Form>
  );
}

const updateWebsite = (id) => (dispatch, getState) => (formValues) => {
  const customStyles = nextCustomStylesSelector(getState(), { formValues });

  return api
    .patch(`/websites/${id}`, {
      customStyles,
    })
    .then((payload) => {
      dispatch(changeWebsite(payload));
      dispatch(initialize(formName, initialSassValuesSelector(getState(), { pickBy: ['$borderRadius'] })));
    });
};

export const GlobalDecorated = compose(
  connect(
    (state) => ({
      website: websiteSelector(state),
      initialValues: initialSassValuesSelector(state, {
        pickBy: ['$borderRadius'],
      }),
      formName,
    }),
    {
      updateWebsite,
    },
    (stateProps, dispatchProps) => {
      const websiteId = get(stateProps.website, 'id');

      return {
        formName,
        initialValues: stateProps.initialValues,
        faviconUrl: get(stateProps.website, 'assets.favicon'),
        faviconApiUrl: `/websites/${websiteId}/files/favicon`,
        onSubmit: dispatchProps.updateWebsite(websiteId),
      };
    }
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })
)(Global);
