import React from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import { startsWith } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/pro-solid-svg-icons';

import { Button } from 'components/Button';
import './index.css';

function LngSelector() {
  const { t } = useTranslation();
  const i18n = getI18n();
  const isGermanLng = startsWith(i18n.language, 'de');

  return (
    <div className="header__lng-buttons" data-e2e="headerLngSelector">
      <Button variant="lng-button" data-e2e="enButton" active={!isGermanLng} onClick={() => i18n.changeLanguage('en')}>
        {t('header.lngSelector.en')}
      </Button>
      <div className="header__lng-divider" />
      <Button variant="lng-button" data-e2e="deButton" active={isGermanLng} onClick={() => i18n.changeLanguage('de')}>
        {t('header.lngSelector.de')}
      </Button>
    </div>
  );
}

export function Header() {
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="header__title">{t('mainHeaderTitle')}</div>
      <div className="header__right">
        <div className="header__globe">
          <FontAwesomeIcon icon={faGlobeAmericas} />
        </div>
        <LngSelector />
      </div>
    </div>
  );
}
