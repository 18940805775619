import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { SET_CORPORATE_TUBES_SORTING } from 'actions';
import { sortCorporateTubesBySelector } from 'selectors';

import './index.css';

const setCorporateTubesSorting = (payload) => ({
  type: SET_CORPORATE_TUBES_SORTING,
  payload,
});

const sortingStates = {
  ASC: 'asc',
  DESC: 'desc',
  UNSORTED: 'unsorted',
};

const toggleSort = (field) => (dispatch, getState) => {
  const sortBy = sortCorporateTubesBySelector(getState());

  switch (field === sortBy.field && sortBy.sorting) {
    case sortingStates.ASC:
      dispatch(setCorporateTubesSorting({ field, sorting: sortingStates.DESC }));
      break;
    case sortingStates.DESC:
      dispatch(setCorporateTubesSorting(undefined));
      break;
    default:
      dispatch(setCorporateTubesSorting({ field, sorting: sortingStates.ASC }));
      break;
  }
};

export function SortColumn({ field }) {
  const dispatch = useDispatch();
  const sortBy = useSelector(sortCorporateTubesBySelector);

  const isCurrentSortingField = field === sortBy.field;

  const sort = isCurrentSortingField ? sortBy.sorting : sortingStates.UNSORTED;

  return (
    <div
      data-e2e={`sort-by-${field}-${sort}`}
      className="sort fa-layers fa-fw"
      onClick={() => dispatch(toggleSort(field))}
    >
      <FontAwesomeIcon
        icon={faSortUp}
        className={`sort__icon ${sort === sortingStates.ASC ? '' : 'sort__icon--half-opacity'}`}
      />
      <FontAwesomeIcon
        icon={faSortDown}
        className={`sort__icon ${sort === sortingStates.DESC ? '' : 'sort__icon--half-opacity'}`}
      />
    </div>
  );
}
