import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowUp, faLongArrowDown } from '@fortawesome/pro-light-svg-icons';
import { InputCheck } from 'components/Input/InputCheck';
import { Badge } from 'components/Badge';
import { useTranslation } from 'react-i18next';
import { map, slice, size, concat, filter, get } from 'lodash';
import { useSelector } from 'react-redux';
import { websiteSelector } from 'selectors';
import './index.css';

function SortedItem({ index, children, handleButtonUpClick, handleButtonDownClick, type }) {
  return (
    <div className="sorted-video-item" data-e2e={`${type}SortedItem`}>
      <div>
        <Badge variant="live">{index}</Badge>
      </div>
      <div className="sorted-video-item__content">{children}</div>
      {handleButtonUpClick && (
        <div className="sorted-video-item__buttons" onClick={handleButtonUpClick} data-e2e="button-up">
          <FontAwesomeIcon icon={faLongArrowUp} size="xs" />
        </div>
      )}
      {handleButtonDownClick && (
        <div className="sorted-video-item__buttons" onClick={handleButtonDownClick} data-e2e="button-down">
          <FontAwesomeIcon icon={faLongArrowDown} size="xs" />
        </div>
      )}
    </div>
  );
}

export function SortedVideoList({ items, name, handleButtonUpClick, handleButtonDownClick, handleCheckboxChanged }) {
  const { t } = useTranslation();

  return (
    <div className="sorted-video-list" data-e2e="sorted-video-list" name={name}>
      {map(items, ({ type, enabled }, index) => {
        return (
          <SortedItem
            key={type}
            handleButtonUpClick={index > 0 && (() => handleButtonUpClick(index))}
            handleButtonDownClick={index + 1 < items.length && (() => handleButtonDownClick(index))}
            index={index + 1}
            type={type}
          >
            <InputCheck
              onChange={() => handleCheckboxChanged(type)}
              title={t(`sortedVideoList.${type}`)}
              value={enabled}
              name={type}
            />
          </SortedItem>
        );
      })}
    </div>
  );
}

export function SortedVideoListDecorated({ value: values, onChange, name }) {
  const website = useSelector(websiteSelector);
  const ratingFeatureEnabled = get(website, 'ratingFeatureEnabled');

  const items = ratingFeatureEnabled ? values : filter(values, (v) => v.type !== 'bestRated');

  const handleCheckboxChanged = (type) => {
    if (onChange) {
      const nextValues = map(items, (item) => {
        return item.type === type
          ? {
              type,
              enabled: !item.enabled,
            }
          : item;
      });
      onChange(nextValues);
    }
  };

  const handleButtonUpClick = (index) => {
    if (index !== 0) {
      const nextValues = concat(slice(items, 0, index - 1), items[index], items[index - 1], slice(items, index + 1));
      onChange(nextValues);
    }
  };

  const handleButtonDownClick = (index) => {
    if (index + 1 !== size(items)) {
      const nextValues = concat(slice(items, 0, index), items[index + 1], items[index], slice(items, index + 2));
      onChange(nextValues);
    }
  };

  return (
    <SortedVideoList
      items={items}
      name={name}
      handleButtonUpClick={handleButtonUpClick}
      handleButtonDownClick={handleButtonDownClick}
      handleCheckboxChanged={handleCheckboxChanged}
    />
  );
}

export const renderSortedVideoList = ({ input: { name, onChange, value }, title, description }) => (
  <Form.Group controlId={name}>
    {title && <Form.Label className="title">{title}</Form.Label>}

    <SortedVideoListDecorated name={name} onChange={onChange} value={value} />

    {description && <Form.Text className="text-muted">{description}</Form.Text>}
  </Form.Group>
);
