import React from 'react';
import { isDirty, isPristine, isSubmitting, isValid } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Buttons } from './Buttons';
import './index.css';

export function Form({ reset, handleSubmit, children, formTitle, formName, disabled }) {
  const { t } = useTranslation();
  const dirty = useSelector(isDirty(formName));
  const pristine = useSelector(isPristine(formName));
  const submitting = useSelector(isSubmitting(formName));
  const valid = useSelector(isValid(formName));

  return (
    <form onSubmit={handleSubmit} data-e2e={`${formTitle}Form`} className="cta-form">
      <div className="form__title">
        {t(formTitle)}
        {dirty && <span data-e2e="formStatus">&nbsp;*</span>}
      </div>
      <div className="form__body">
        {children}
        <Buttons
          disabled={disabled}
          pristine={pristine}
          reset={reset}
          submitting={submitting}
          valid={valid}
          primaryTitle={t('save')}
          secondaryTitle={t('discard')}
          secondaryDisabled={submitting || pristine}
        />
      </div>
    </form>
  );
}
