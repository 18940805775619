import { SubmissionError } from 'redux-form';
import { get } from 'lodash';
import i18n from 'i18next';

import { showNotification } from 'actions';
import { NOTIFICATION_TYPES } from 'components/Notifications';
import { callApi } from './http-client';
import { getStore } from '../store/configureStore';

const handleFormViolations = (violations = []) => {
  const submissionError = {};

  violations.forEach((violation) => {
    const propertyName = violation.path.split('.').pop();
    submissionError[propertyName] = violation.message;
  });

  throw new SubmissionError(submissionError);
};

const dispatchErrorNotification = (message) => {
  const { dispatch } = getStore();

  dispatch(
    showNotification({
      message,
      timeout: 5000,
      type: NOTIFICATION_TYPES.ERROR,
    })
  );
};

const handleGenericError = (errors) => {
  if (errors instanceof TypeError) {
    const translatedMessage = i18n.t('noConnectionError');
    dispatchErrorNotification(translatedMessage);
  } else {
    const errorMessage = get(errors, 'json.error.message');

    if (errorMessage) {
      dispatchErrorNotification(errorMessage);
    }
  }
};

const handleErrors = (errors) => {
  if (!errors) {
    throw new Error('No error provided');
  }

  handleGenericError(errors);

  const violations = get(errors, 'json.violations', []);
  if (violations.length) {
    handleFormViolations(violations);
  }

  throw new SubmissionError(errors);
};

const request = (...args) => callApi(...args).catch(handleErrors);

export const api = {
  get: (url) => request(url, 'GET'),
  patch: (url, data) => request(url, 'PATCH', data),
  post: (url, data, json) => request(url, 'POST', data, json),
  delete: (url) => request(url, 'DELETE'),
};
